import React, { useEffect, useState } from "react";
import { Box, Button, Modal } from "@mui/material";
import { NumberFormatter } from "react-number-formatter";
import { Typography } from "@material-ui/core";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CloseIcon from "@mui/icons-material/Close";
import useStyles from "./styles";
import EmployeeModal from "./EmployeeModal";
import ApiService from "../../../service/ApiService";
import Employee from "../../../model/employee";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "40rem",
  bgcolor: "background.paper",
  border: "none",
  borderRadius: "8px",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
  maxHeight: "90vh", // Restrict maximum height to 80% of the viewport height
  overflowY: "auto", // Enable vertical scrolling
  "@media (max-width: 640px)": {
    width: "80%",
  },
};

interface Props {
  employee: Employee;
  handleUpdate: any;
  handleDelete: any;
}

const EmployeeDetailModal = ({
  employee,
  handleUpdate,
  handleDelete,
}: Props) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    //ApiService.get("")
    //TODO: get current project
  });

  return (
    <div>
      <Button onClick={handleOpen}>
        {handleUpdate ? (
          <MoreVertIcon />
        ) : (
          <Typography>{employee.firstName}</Typography>
        )}
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ overflowY: "auto" }}
      >
        <Box sx={style}>
          <div className={classes.headerDiv}>
            <Typography className={classes.title}>Employee info</Typography>
            <div className={classes.headerSubDiv}>
              {handleUpdate && (
                <EmployeeModal
                  employee={employee}
                  handleUpdate={handleUpdate}
                  handleDelete={handleDelete}
                />
              )}
              <Button onClick={handleClose} sx={{ color: "black" }}>
                <CloseIcon />
              </Button>
            </div>
          </div>
          <hr />
          <form className={classes.form}>
            <div className={classes.inputDiv}>
              <Typography className={classes.label}>Name</Typography>
              <input
                className={classes.inputDetail}
                type="text"
                value={employee.firstName + " " + employee.lastName}
                disabled
              />
            </div>
            <div className={classes.inputGroup}>
              <div className={classes.inputDiv}>
                <Typography className={classes.label}>Email</Typography>
                <input
                  className={classes.inputDetail}
                  value={employee.email}
                  type="email"
                  disabled
                />
              </div>
              <div className={classes.inputDiv}>
                <Typography className={classes.label}>Phone</Typography>
                <div className={classes.inputDetail}>
                  <NumberFormatter
                    defaultCountry="USA"
                    value={employee.phone}
                    disabled
                  />
                </div>
              </div>
            </div>
            <div className={classes.inputGroup}>
              <div className={classes.inputDiv}>
                <Typography className={classes.label}>Street</Typography>
                <input
                  className={classes.inputDetail}
                  value={employee.street}
                  disabled
                />
              </div>
              <div className={classes.inputDiv}>
                <Typography className={classes.label}>ZIP</Typography>
                <input
                  className={classes.inputDetail}
                  value={employee.zip}
                  disabled
                />
              </div>
            </div>
            <div className={classes.inputGroup}>
              <div className={classes.inputDiv}>
                <Typography className={classes.label}>City</Typography>
                <input
                  className={classes.inputDetail}
                  value={employee.city}
                  disabled
                />
              </div>
              <div className={classes.inputDiv}>
                <Typography className={classes.label}>State</Typography>
                <input
                  className={classes.inputDetail}
                  value={employee.state}
                  disabled
                />
              </div>
            </div>
            <div className={classes.inputGroup}>
              {employee.itin && (
                <div className={classes.inputDiv}>
                  <Typography className={classes.label}>ITIN</Typography>
                  <input
                    className={classes.inputDetail}
                    value={employee.itin}
                    disabled
                  />
                </div>
              )}
              {employee.socialSecurityNumber && (
                <div className={classes.inputDiv}>
                  <Typography className={classes.label}>SSN</Typography>
                  <input
                    className={classes.inputDetail}
                    value={employee.socialSecurityNumber}
                    disabled
                  />
                </div>
              )}
            </div>
            <div className={classes.inputDiv}>
              <Typography className={classes.label}>Rating</Typography>
              <input
                className={classes.inputDetail}
                value={employee.rating}
                disabled
              />
            </div>
          </form>
        </Box>
      </Modal>
    </div>
  );
};

export default EmployeeDetailModal;
