import React from "react";
import Pathnames from "../../../model/enum/Pathnames";
import useStyles from "./styles";
import { Link, useLocation } from "react-router-dom";
import { Typography } from "@material-ui/core";
import logo from "../../../assets/main.png";
import qrcode from "../../../assets/DoorVi.png";
import facebook from "../../../assets/social-media/facebook.svg";
import instagram from "../../../assets/social-media/instagram.svg";
import linkedin from "../../../assets/social-media/linkedin.svg";
import youtube from "../../../assets/social-media/youtube.svg";

const Footer = () => {
  const classes = useStyles();
  const location = useLocation();

  let pathname = location.pathname;
  var year = new Date().getFullYear();

  const footerAllowedPaths = () => {
    return (
      pathname === Pathnames.HOMEPAGE ||
      pathname === Pathnames.REQUEST ||
      pathname === Pathnames.GALLERY ||
      pathname === Pathnames.REVIEWS ||
      pathname === Pathnames.ABOUT ||
      pathname === Pathnames.CONTACT ||
      pathname === Pathnames.TERMS ||
      pathname === Pathnames.PRIVACY ||
      pathname === Pathnames.APPOINTMENT ||
      pathname === Pathnames.FINANCE ||
      pathname.includes(Pathnames.SERVICE)
    );
  };

  return (
    <>
      {footerAllowedPaths() && (
        <footer className={classes.footer}>
          <div className={classes.container}>
            <div className={classes.logoDiv}>
              <img
                src={logo}
                className={classes.logo}
                alt="logo"
                loading="lazy"
              />
            </div>
            <div className={classes.linksDiv}>
              <Typography className={classes.title}>Links</Typography>
              <Typography>
                <Link className={classes.text} to={Pathnames.FINANCE}>
                  Finance your project
                </Link>
              </Typography>
              <Typography>
                <Link className={classes.text} to={Pathnames.APPOINTMENT}>
                  Schedule appointment
                </Link>
              </Typography>
              <Typography>
                <Link className={classes.text} to={Pathnames.REQUEST}>
                  Request service
                </Link>
              </Typography>
              <Typography>
                <Link className={classes.text} to={Pathnames.REVIEWS}>
                  Write a review
                </Link>
              </Typography>
              <Typography>
                <Link className={classes.text} to="">
                  Help
                </Link>
              </Typography>
            </div>
            <div className={classes.linksDiv}>
              <Typography className={classes.title}>Contact</Typography>
              <Typography className={classes.text}>
                info@setupremodeling.com
              </Typography>
              <Typography>
                <Link className={classes.text} to={Pathnames.CONTACT}>
                  setupremodeling.com
                </Link>
              </Typography>
            </div>
            <div className={classes.linksDiv}>
              <Typography className={classes.title}>Legal Terms</Typography>
              <Typography>
                <Link className={classes.text} to={Pathnames.TERMS}>
                  Terms and Conditions
                </Link>
              </Typography>
              <Typography>
                <Link className={classes.text} to={Pathnames.PRIVACY}>
                  Privacy Policy
                </Link>
              </Typography>
              <Typography>
                <Link className={classes.text} to={Pathnames.ABOUT}>
                  About Us
                </Link>
              </Typography>
            </div>
            <div className={classes.callDiv}>
              <a
                href="https://call.doorvi.co/?vm=651a2c8d49614d7ada191fb9"
                target="blank"
                className={classes.anchor}
              >
                <img src={qrcode} className={classes.qrCode} alt="doorvi" />
              </a>
              <div className={classes.socialMediaDiv}>
                <a
                  href="https://www.facebook.com/SetupRemodeling"
                  target="blank"
                  className={classes.anchor}
                >
                  <img
                    src={facebook}
                    className={classes.socialMediaContent}
                    alt="facebook"
                  />
                </a>
                <a
                  href="https://www.linkedin.com"
                  target="blank"
                  className={classes.anchor}
                >
                  <img
                    src={linkedin}
                    className={classes.socialMediaContent}
                    alt="linkedin"
                  />
                </a>
                <a
                  href="https://www.instagram.com/setupremodeling"
                  target="blank"
                  className={classes.anchor}
                >
                  <img
                    src={instagram}
                    className={classes.socialMediaContent}
                    alt="instagram"
                  />
                </a>
                <a
                  href="https://www.youtube.com/channel/UCY27LbgoPjaUr1qybgy4zAQ"
                  target="blank"
                  className={classes.anchor}
                >
                  <img
                    src={youtube}
                    className={classes.socialMediaContent}
                    alt="youtube"
                  />
                </a>
              </div>
            </div>
          </div>
          <div>
            <div className={classes.lineDiv}>
              <hr
                style={{ width: "16rem" }}
              />
            </div>
            <label className={classes.copyright}>
              © Copyright {year} Setup Remodeling, Inc. All Rights Reserved
            </label>
          </div>
        </footer>
      )}
    </>
  );
};

export default Footer;
