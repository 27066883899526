import React, { ChangeEvent, useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TransformDate from "../../utils/TransformDate";
import {
  PrimaryColor,
  TableBodyGrey,
  TableHeaderGrey,
} from "../../shared/styles/colors";
import ClientModel from "../../model/client";
import DatabaseEntities from "../../model/enum/DatabaseEntities";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import AlertService from "../../service/AlertService";
import ApiService from "../../service/ApiService";
import ToastMessages from "../../model/enum/ToastMessages";
import { Spinner, ClientModal } from "../index";
import { Card, CardContent, Typography, Button } from "@material-ui/core";
import { TablePagination } from "@mui/material";
import { ToastContainer } from "react-toastify";
import ClientDetailModal from "./ClientDetailModal";
import CachedIcon from "@mui/icons-material/Cached";
import useStyles from "../styles/cardStyles";

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: TableHeaderGrey,
    color: PrimaryColor,
    fontWeight: 700,
    whiteSpace: "nowrap",
    zIndex: 0,
    position: "static",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    color: TableBodyGrey,
    fontFamily: "monospace",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const Clients = () => {
  const classes = useStyles();
  const [clients, setClients] = useState<ClientModel[]>([]);
  const [items, setItems] = useState<ClientModel[]>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [toggleSpinner, setToggleSpinner] = useState(true);

  //Get all the clients from the database
  useEffect(() => {
    const fetchData = async () => {
      await ApiService.get<ClientModel[]>(DatabaseEntities.CLIENTS).then(
        (data: ClientModel[]) => {
          if (data.length === 0) return;

          setClients(data);
          setItems(data.slice(0, rowsPerPage));
        }
      );
      setToggleSpinner(false);
    };

    if (toggleSpinner && clients.length === 0) fetchData();
  }, [clients]);

  const handleCreate = async (payload: any, notify: boolean = true) => {
    await ApiService.post<ClientModel>(DatabaseEntities.CLIENTS, payload)
      .then((data: any) => {
        if (data.existingClient) {
          AlertService.error(data.error);
          return;
        }
        if (notify) AlertService.succes(ToastMessages.CREATED);
        resetList();
      })
      .catch(() => {
        AlertService.error(ToastMessages.CREATED_FAILED);
      });
  };

  const handleUpdate = async (payload: any, notify: boolean = true) => {
    await ApiService.put<ClientModel>(
      `${DatabaseEntities.CLIENTS}/${payload.id}`,
      payload
    )
      .then((data: any) => {
        if (data.existingClient) {
          AlertService.error(data.error);
          return;
        }

        if (notify) AlertService.succes(ToastMessages.UPDATED);
        resetList();
      })
      .catch(() => {
        AlertService.error(ToastMessages.UPDATE_FAILED);
      });
  };

  const handleDelete = async (payload: any, notify: boolean = true) => {
    await ApiService.delete<any>(`${DatabaseEntities.CLIENTS}/${payload.id}`)
      .then(() => {
        if (notify) AlertService.succes(ToastMessages.DELETED);
        resetList();
      })
      .catch(() => {
        AlertService.error(ToastMessages.DELETE_FAILED);
      });
  };

  const resetList = (): void => {
    setClients([]);
    setItems([]);
    setPage(0);
    setToggleSpinner(true);
  };

  const handlePageChange = (
    event: ChangeEvent<unknown> | null,
    pg: number,
    itemsPerPage: number = rowsPerPage
  ) => {
    setToggleSpinner(true);
    setPage(pg);
    let MaxElem = pg * itemsPerPage;

    items.forEach((_itm: ClientModel, index) => {
      items.splice(index);
    });

    for (let i = MaxElem; i < MaxElem + itemsPerPage; i++) {
      if (i < clients.length) items.push(clients[i]);
    }

    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    setToggleSpinner(false);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    let pg = parseInt(event.target.value);
    setRowsPerPage((rowsPerPage) => pg);
    setPage((page) => 0);
    handlePageChange(null, 0, pg);
  };

  return (
    <>
      <Card raised className={classes.card}>
        <div className={classes.headerContainer}>
          <Typography className={classes.title}>Clients</Typography>
          <div className={classes.headerDiv}>
            <ClientModal handleCreate={handleCreate} />
            <Button onClick={resetList}>
              <CachedIcon />
            </Button>
          </div>
        </div>
        <CardContent>
          <Paper>
            <TableContainer>
              <Table
                stickyHeader
                aria-label="customized table"
                padding="normal"
                size="medium"
              >
                <TableHead>
                  <TableRow>
                    <StyledTableCell align="right">Id</StyledTableCell>
                    <StyledTableCell align="right">Name</StyledTableCell>
                    <StyledTableCell align="right">Phone</StyledTableCell>
                    <StyledTableCell align="right">Email</StyledTableCell>
                    <StyledTableCell align="right">Address</StyledTableCell>
                    <StyledTableCell align="right">State</StyledTableCell>
                    <StyledTableCell align="right">
                      Service Added
                    </StyledTableCell>
                    <StyledTableCell align="right">Created At</StyledTableCell>
                    <StyledTableCell align="center">
                      <MoreVertIcon />
                    </StyledTableCell>{" "}
                  </TableRow>
                </TableHead>
                {toggleSpinner ? (
                  <Spinner />
                ) : (
                  <TableBody>
                    {items.length > 0 ? (
                      items.map((row: ClientModel) => (
                        <StyledTableRow key={row.id}>
                          <StyledTableCell align="right">
                            {row.id}
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            {row.firstName} {row.lastName}
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            {row.phone}
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            {row.email}
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            {row.street || row.state || row.city ? (
                              <>
                                {row.street ? `${row.street},` : ""} {row.city}{" "}
                                {row.state} {row.zip}
                              </>
                            ) : (
                              <Typography>--------------</Typography>
                            )}
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            {row.state ? (
                              <>{row.state}</>
                            ) : (
                              <Typography>--</Typography>
                            )}
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            {row.service}
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            {TransformDate.toFullDate(row.createdAt)}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            <ClientDetailModal
                              client={row}
                              handleUpdate={handleUpdate}
                              handleDelete={handleDelete}
                            />
                          </StyledTableCell>
                        </StyledTableRow>
                      ))
                    ) : (
                      <StyledTableCell align="right">No items</StyledTableCell>
                    )}
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Paper>
          <TablePagination
            component="div"
            count={clients.length}
            page={page}
            onPageChange={handlePageChange}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </CardContent>
      </Card>
      <ToastContainer />
    </>
  );
};

export default Clients;
