import React, { useEffect, useState } from "react";
import { Box, Button, Modal, Tabs, Tab } from "@mui/material";
import {
  Button as Btn,
  Card,
  CardContent,
  Typography,
} from "@material-ui/core";
import { DarkGrey, PrimaryColor } from "../../shared/styles/colors";
import { Spinner } from "../../components";
import Status from "../../model/enum/StatusClass";
import EstimateModal from "./EstimateModal";
import ProductDetailModal from "../checklist/product/ProductDetailModal";
import TransformDate from "../../utils/TransformDate";
import ApiService from "../../service/ApiService";
import DatabaseEntities from "../../model/enum/DatabaseEntities";
import StagedPaymentModel from "../../model/stagedPayment";
import BundleItemModel from "../../model/bundleItem";
import EstimateModel from "../../model/estimate";
import CloseIcon from "@mui/icons-material/Close";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ToggleOnIcon from "@mui/icons-material/ToggleOn";
import ToggleOffIcon from "@mui/icons-material/ToggleOff";
import MainLogo from "../../assets/main.png";
import useStyles from "./styles";
import EstimatePDFView from "./EstimateView";

const style = {
  position: "absolute" as "absolute",
  top: "5%", // Start from a fixed top position
  left: "50%",
  transform: "translateX(-50%)", // Only horizontally center the modal
  width: "90% !important",
  maxWidth: "85rem",
  bgcolor: "#F9F9F9",
  border: "none",
  borderRadius: "8px",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
  maxHeight: "90vh", // Restrict maximum height to 80% of the viewport height
  overflowY: "auto", // Enable vertical scrolling
  "@media (max-width: 640px)": {
    width: "80%",
  },
};

interface Props {
  estimate: EstimateModel;
  handleUpdate: any;
  handleDelete: any;
}

const EstimateDetailModal = ({
  estimate,
  handleUpdate,
  handleDelete,
}: Props) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [toggleSpinner, setToggleSpinner] = useState(false);

  const [value, setValue] = useState(0); // Active tab
  const [downPayment, setDownPayment] = useState(false);
  const [stagedPayments, setStagedPayments] = useState<StagedPaymentModel[]>(
    []
  );

  const handleTabChange = (e: any, newValue: number) => {
    setValue(newValue);
  };

  useEffect(() => {
    const fetchData = async () => {
      setToggleSpinner(true);

      await ApiService.get<StagedPaymentModel[]>(
        `${DatabaseEntities.STAGED_PAYMENTS}/${estimate.id}`
      ).then((data: StagedPaymentModel[]) => {
        setDownPayment(data && data.length > 0);
        setStagedPayments(data);
      });

      setToggleSpinner(false);
    };

    if (open && stagedPayments.length <= 0 && estimate.Status?.id == 15)
      fetchData();
  }, [open, stagedPayments]);

  return (
    <div>
      <Button onClick={handleOpen}>
        <MoreVertIcon />
      </Button>
      {open && (
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{ overflowY: "auto" }}
        >
          {toggleSpinner ? (
            <Spinner />
          ) : (
            <Box sx={style}>
              <Card style={{ padding: "25px" }}>
                <div className={classes.headerDiv}>
                  <Typography
                    variant="subtitle1"
                    style={{
                      color: DarkGrey,
                      textAlign: "center",
                      width: "auto",
                      fontSize: "1.1rem",
                    }}
                  >
                    Estimate
                  </Typography>
                  <div className={classes.headerSubDiv}>
                    <EstimateModal
                      estimate={estimate}
                      handleUpdate={handleUpdate}
                      handleDelete={handleDelete}
                    />
                    <Button onClick={handleClose} sx={{ color: "black" }}>
                      <CloseIcon />
                    </Button>
                  </div>
                </div>
                <hr />
                <div>
                  <Box
                    sx={{
                      width: "20%",
                      borderBottom: 1,
                      borderColor: "divider",
                    }}
                  >
                    <Tabs
                      value={value}
                      onChange={handleTabChange}
                      centered
                      variant="standard"
                      textColor="inherit"
                      indicatorColor="primary"
                      TabIndicatorProps={{
                        sx: { height: 2, bgcolor: PrimaryColor }, // Customize the underline
                      }}
                    >
                      <Tab
                        label={"Details"}
                        sx={{
                          opacity: value === 0 ? 1 : 0.5,
                          textTransform: "capitalize",
                        }}
                      />
                      <Tab
                        label="PDF View"
                        sx={{
                          opacity: value === 1 ? 1 : 0.5,
                          textTransform: "capitalize",
                        }}
                      />
                    </Tabs>
                  </Box>
                </div>
              </Card>
              {value === 0 ? (
                <form className={classes.form}>
                  <Card style={{ width: "80%", padding: "5px" }}>
                    <CardContent>
                      <div className={classes.headerDiv}>
                        <div className="d-flex col align-items-center">
                          <Typography
                            style={{
                              color: PrimaryColor,
                              fontSize: "2rem",
                              fontWeight: "700",
                              textTransform: "uppercase",
                              marginRight: "10px",
                            }}
                          >
                            Estimate
                          </Typography>
                          <Typography
                            style={{ color: PrimaryColor }}
                          >{`#${estimate.code}`}</Typography>
                        </div>
                        <img
                          src={MainLogo}
                          alt="logo"
                          width={120}
                          height={50}
                        />
                      </div>
                      <div className="d-flex col w-100 mt-2">
                        <div className="d-flex row">
                          <Typography>Setup Remodeling Inc.</Typography>
                          <Typography>72 Cash St.</Typography>
                          <Typography>Fall River Ma 02723</Typography>
                        </div>
                        <div className="d-flex row">
                          <Typography>sales@setupremodeling.com</Typography>
                          <Typography>+1 (800)-530-4099</Typography>
                          <Typography>wwww.setupremodeling.com</Typography>
                        </div>
                        <div className="d-flex row mx-1">
                          <Typography>Bill to:</Typography>
                          <input
                            className={classes.inputDetail}
                            value={
                              estimate.Client?.firstName +
                              " " +
                              estimate.Client?.lastName
                            }
                            disabled
                          />
                          <input
                            className={classes.inputDetail}
                            value={estimate.Client?.email}
                            disabled
                          />
                          <input
                            className={classes.inputDetail}
                            value={`${estimate.Client?.street}, ${estimate.Client?.city}, ${estimate.Client?.state}`}
                            disabled
                          />
                        </div>
                      </div>
                      <hr />
                      <div className="table-container">
                        <table className="styled-table">
                          <thead style={{ color: "rgba(117, 117, 117, 1)" }}>
                            <td>Task</td>
                            <td>Description</td>
                            <td>Quantity</td>
                            <td>Rate</td>
                            <td>Total</td>
                            <td>
                              <MoreVertIcon />
                            </td>
                          </thead>
                          <tbody>
                            {estimate?.items?.map((row) => (
                              <React.Fragment key={row.id}>
                                {/* Parent Row */}
                                <tr key={row.id}>
                                  <td>
                                    <input
                                      type="text"
                                      value={row.task}
                                      disabled
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      value={row.description}
                                      disabled
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="number"
                                      value={row.quantity}
                                      disabled
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="number"
                                      value={row.rate}
                                      disabled
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="number"
                                      value={row.amount}
                                      disabled
                                    />
                                  </td>
                                </tr>
                                {/* Child Rows */}
                                <>
                                  {row.BundleItems.map(
                                    (child: BundleItemModel) => (
                                      <tr key={child.id}>
                                        <td
                                          style={{
                                            paddingLeft: "30px",
                                          }}
                                        >
                                          <input
                                            style={{
                                              background: "#F1F2F3",
                                              color: "grey",
                                            }}
                                            type="text"
                                            placeholder="Select task"
                                            value={child.Product.name}
                                            disabled
                                          />
                                        </td>
                                        <td>
                                          <input
                                            style={{
                                              background: "#F1F2F3",
                                              color: "grey",
                                            }}
                                            type="text"
                                            placeholder="Add a description.."
                                            value={child.Product.description}
                                            disabled
                                          />
                                        </td>
                                        <td>
                                          <input
                                            style={{
                                              background: "#F1F2F3",
                                              color: "grey",
                                            }}
                                            type="number"
                                            placeholder="01"
                                            min={1}
                                            value={child.quantity}
                                            disabled
                                          />
                                        </td>
                                        <td>
                                          <input
                                            style={{
                                              background: "#F1F2F3",
                                              color: "grey",
                                            }}
                                            type="number"
                                            placeholder="01"
                                            min={1}
                                            value={child.Product.price}
                                            disabled
                                          />
                                        </td>
                                        <td>
                                          <input
                                            style={{
                                              background: "#F1F2F3",
                                              color: "grey",
                                            }}
                                            type="number"
                                            placeholder="01"
                                            min={1}
                                            value={
                                              child.Product.price *
                                              child.quantity
                                            }
                                            disabled
                                          />
                                        </td>
                                        <td>
                                          <ProductDetailModal
                                            product={child.Product}
                                            handleUpdate={undefined}
                                            handleDelete={undefined}
                                          />
                                        </td>
                                      </tr>
                                    )
                                  )}
                                </>
                              </React.Fragment>
                            ))}
                          </tbody>
                        </table>
                      </div>
                      <div className="d-flex col w-100 mt-2 align-items-center justify-content-end">
                        <Typography>Total Amount:</Typography>
                        <Typography
                          style={{
                            color: PrimaryColor,
                            fontSize: "20px",
                            marginLeft: "10px",
                          }}
                        >
                          ${estimate.total},00
                        </Typography>
                      </div>
                      {estimate.discount > 0 && (
                        <div className="d-flex col w-100 mt-2 align-items-center justify-content-end">
                          <Typography>Discount:</Typography>
                          <Typography
                            style={{
                              color: PrimaryColor,
                              fontSize: "17px",
                              marginLeft: "10px",
                            }}
                          >
                            {estimate.discount}%
                          </Typography>
                        </div>
                      )}
                      <div className="d-flex row mt-5 align-items-center">
                        <Typography>Notes to costumer</Typography>
                        <textarea
                          className={classes.textareaDetail}
                          value={estimate.notes}
                          disabled
                        />
                      </div>
                    </CardContent>
                  </Card>
                  {/** Details */}
                  <div
                    style={{
                      width: "24%",
                      alignItems: "center",
                      justifyContent: "center",
                      marginLeft: "15px",
                      padding: "5px",
                    }}
                  >
                    <Card>
                      <CardContent>
                        <Typography
                          style={{
                            color: PrimaryColor,
                            fontSize: "20px",
                            fontWeight: 600,
                          }}
                        >
                          Details
                        </Typography>
                        <div className="d-flex col mt-4 justify-content-between align-items-center">
                          <Typography
                            style={{ color: "grey", fontSize: "15px" }}
                          >
                            Estimate total:
                          </Typography>
                          <Typography>${estimate.total},00</Typography>
                        </div>
                        <div className="d-flex col justify-content-between align-items-center">
                          <Typography
                            style={{ color: "grey", fontSize: "15px" }}
                          >
                            Due date:
                          </Typography>
                          <Typography>
                            {TransformDate.toCompleteDate(estimate.dueDate)}
                          </Typography>
                        </div>
                        <div className="d-flex col justify-content-between align-items-center">
                          <Typography
                            style={{ color: "grey", fontSize: "15px" }}
                          >
                            Status:
                          </Typography>
                          <div style={{ width: "50%" }}>
                            <Typography
                              style={Status.mapStatusColor(
                                estimate.Status?.name
                              )}
                            >
                              <div
                                style={Status.mapStatusRoundedColor(
                                  estimate.Status?.name
                                )}
                              ></div>
                              {estimate.Status?.name}
                            </Typography>
                          </div>
                        </div>
                        <div className="d-flex col justify-content-between align-items-center">
                          <Typography
                            style={{ color: "grey", fontSize: "15px" }}
                          >
                            Created At:
                          </Typography>
                          <Typography>
                            {TransformDate.toFullDateHours(estimate.createdAt)}
                          </Typography>
                        </div>
                        <hr />
                        <div className="d-flex col justify-content-between align-items-center">
                          <Typography
                            style={{ color: "grey", fontSize: "15px" }}
                          >
                            Urgent payment:
                          </Typography>
                          {estimate.urgentPayment ? (
                            <ToggleOnIcon />
                          ) : (
                            <ToggleOffIcon />
                          )}
                        </div>
                        <div className="d-flex col justify-content-between align-items-center">
                          <Typography
                            style={{ color: "grey", fontSize: "15px" }}
                          >
                            Downpayment:
                          </Typography>
                          {downPayment ? <ToggleOnIcon /> : <ToggleOffIcon />}
                        </div>
                        <div></div>
                      </CardContent>
                    </Card>
                  </div>
                </form>
              ) : (
                <Card style={{ width: "100%", padding: "25px", marginTop: "1rem" }}>
                  <Typography
                    variant="subtitle1"
                    style={{
                      color: DarkGrey,
                      textAlign: "center",
                      width: "auto",
                      fontSize: "1.1rem",
                    }}
                  >
                    Estimate PDF
                  </Typography>
                  <EstimatePDFView estimate={estimate} />
                </Card>
              )}
            </Box>
          )}
        </Modal>
      )}
    </div>
  );
};

export default EstimateDetailModal;
