import React, { useState } from "react";
import { Box, Button, Modal } from "@mui/material";
import { Button as Btn, Typography } from "@material-ui/core";
import DeleteModal from "../../modal/DeleteModal";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "../../../assets/admin/edit-icon.svg";
import useStyles from "./styles";
import { NumberFormatter } from "react-number-formatter";
import US_States from "../../../model/enum/US_States";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "40rem",
  bgcolor: "background.paper",
  border: "none",
  borderRadius: "8px",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
  maxHeight: "90vh", // Restrict maximum height to 80% of the viewport height
  overflowY: "auto", // Enable vertical scrolling
  "@media (max-width: 640px)": {
    width: "80%",
  },
};

const EmployeeModal = ({
  employee,
  handleCreate,
  handleUpdate,
  handleDelete,
}: any) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const edit = employee != null;
  const id = edit ? employee.id : 0;
  const [firstName, setFirstName] = useState(edit ? employee.firstName : null);
  const [lastName, setLastName] = useState(edit ? employee.lastName : null);
  const [email, setEmail] = useState(edit ? employee.email : null);
  const [phone, setPhone] = useState(edit ? employee.phone : null);
  const [rating, setRating] = useState(edit ? employee.rating : null);
  const [zipCode, setZipCode] = useState(
    edit && employee.zip ? employee?.zip : null
  );
  const [street, setStreet] = useState(
    edit && employee.street ? employee.street : null
  );
  const [city, setCity] = useState(
    edit && employee.city ? employee.city : null
  );
  const [state, setState] = useState(
    edit && employee.state ? employee.state : null
  );
  const [itin, setItin] = useState(
    edit && employee.itin ? employee.itin : null
  );
  const [ssn, setSsn] = useState(
    edit && employee.socialSecurityNumber ? employee.socialSecurityNumber : null
  );
  const [toggleItin, setToggleItin] = useState(false);

  const US_State = new US_States();

  const handleFirstName = (e: any) => setFirstName(e.target.value);
  const handleLastName = (e: any) => setLastName(e.target.value);
  const handleEmail = (e: any) => setEmail(e.target.value);
  const handlePhone = (e: any) => setPhone(e);
  const handleRating = (e: any) => setRating(e.target.value);
  const handleZipCode = (e: any) => {
    e.preventDefault();
    let zip = e.target.value;
    if (!isNaN(zip)) {
      if (US_State.isZipValid(zip)) {
        setCity(US_State.getStateCity(zip));
        setState(US_State.getStateName(zip));
      } else {
        setCity("");
        setState("");
      }
      setZipCode(zip);
    }
  };
  const handleStreet = (e: any) => setStreet(e.target.value);
  const handleCity = (e: any) => setCity(e.target.value);
  const handleState = (e: any) => setState(e.target.value);
  const handleItin = (e: any) => setItin(e.target.value);
  const handleSsn = (e: any) => setSsn(e.target.value);
  const handleToggleItin = () => setToggleItin(!toggleItin);

  const register = () => {
    if (invalidInput()) return;

    const payload = {
      id: id,
      firstName: firstName,
      lastName: lastName,
      email: email,
      phone: phone,
      rating: rating,
      street: street,
      city: city,
      state: state,
      zip: zipCode,
      itin: itin,
      socialSecurityNumber: ssn,
    };

    if (edit) {
      handleUpdate(payload);
    } else {
      handleCreate(payload);
    }
    setFirstName(null);
    setLastName(null);
    setEmail(null);
    setPhone(null);
    setRating(null);
    setStreet(null);
    setCity(null);
    setState(null);
    setZipCode(null);

    handleClose();
  };

  const invalidInput = () => {
    return (
      firstName === null ||
      lastName === null ||
      email === null ||
      phone === null ||
      street === null ||
      city === null ||
      state === null ||
      zipCode === null
    );
  };

  return (
    <div>
      {edit ? (
        <Button onClick={handleOpen}>
          <img src={EditIcon} alt="edit icon" />
        </Button>
      ) : (
        <Btn
          className={classes.headerButton}
          variant="contained"
          onClick={handleOpen}
        >
          + Add Employee
        </Btn>
      )}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ overflowY: "auto" }}
      >
        <Box sx={style}>
          <div className={classes.headerDiv}>
            {edit ? (
              <Typography className={classes.title}>
                <DeleteModal item={employee} handleDelete={handleDelete} />
              </Typography>
            ) : (
              <Typography className={classes.title}>Add Employee</Typography>
            )}
            <Button onClick={handleClose} sx={{ color: "black" }}>
              <CloseIcon />
            </Button>
          </div>
          <hr />
          <form className={classes.form}>
            <div className={classes.inputGroup}>
              <input
                type="text"
                placeholder="First Name"
                value={firstName}
                className={classes.input}
                onChange={handleFirstName}
              />
              <input
                type="text"
                placeholder="Last Name"
                className={classes.input}
                value={lastName}
                onChange={handleLastName}
              />
            </div>
            <div className={classes.inputGroup}>
              <input
                type="email"
                placeholder="Email"
                value={email}
                className={classes.input}
                onChange={handleEmail}
              />
              <div className={classes.input}>
                <NumberFormatter
                  defaultCountry="USA"
                  placeholder="Phone"
                  value={phone}
                  getValue={(n: string) => handlePhone(n)}
                />
              </div>
            </div>
            <div className={classes.inputGroup}>
              <input
                type="text"
                placeholder="Street"
                value={street}
                className={classes.input}
                onChange={handleStreet}
              />
              <input
                className={classes.input}
                value={zipCode}
                onChange={handleZipCode}
                placeholder="ZIP code"
              />
            </div>
            <div className={classes.inputGroup}>
              <input
                type="text"
                placeholder="City"
                value={city}
                className={classes.input}
                onChange={handleCity}
              />
              <input
                type="text"
                placeholder="State"
                value={state}
                className={classes.input}
                onChange={handleState}
              />
            </div>
            <div className={classes.inputGroup}>
              <select
                className={classes.input}
                value={itin}
                onChange={handleToggleItin}
              >
                <option value="" disabled>
                  Select between ITIN and SSN
                </option>
                <option value="ITIN n">ITIN</option>
                <option value="SSN">SSN</option>
              </select>
              {toggleItin ? (
                <input
                  type="text"
                  value={itin}
                  className={classes.input}
                  onChange={handleItin}
                  placeholder="Number"
                />
              ) : (
                <input
                  type="text"
                  value={ssn}
                  className={classes.input}
                  onChange={handleSsn}
                  placeholder="Number"
                />
              )}
            </div>
            {edit && (
              <div className={classes.inputGroup}>
                <input
                  type="number"
                  placeholder="Rating"
                  min={0}
                  max={5}
                  value={rating}
                  className={classes.input}
                  onChange={handleRating}
                />
              </div>
            )}
          </form>
          <div className={classes.buttonDiv}>
            <Btn
              className={classes.button}
              onClick={() => register()}
              disabled={invalidInput()}
            >
              Save
            </Btn>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default EmployeeModal;
