import React, { useEffect, useState } from "react";
import { Card, CardContent } from "@material-ui/core";

import useStyles from "./styles";
import BudgetCalculator from "./breakdown/BudgetBreakdown";

const Budget = ({ category }: any) => {
  const classes = useStyles();
  const [toggleBudget, setToggleBudget] = useState(false);
  const [budget, setBuget] = useState<string | undefined>("");

  const calculateBudget = () => {
    if (budget) setToggleBudget(true);
  };

  const inputBudget = (e: any) => {
    let bgt = e.target.value;
    if (!isNaN(bgt)) {
      setBuget(bgt);
    } else {
      setBuget(undefined);
    }
  };

  const invalidBugdet = () => {
    return budget === undefined
      ? true
      : budget.length === 0
      ? true
      : budget.length < 5
      ? true
      : false;
  };

  useEffect(() => {
    const keyDownHandler = (event: {
      key: string;
      preventDefault: () => void;
    }) => {
      if (event.key === "Enter") {
        event.preventDefault();
        if (budget) calculateBudget();
      }
    };

    document.addEventListener("keydown", keyDownHandler);

    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  });

  // Suggest budget to 15000 when is bathroom and then 20000 when is kitchen

  return (
    <div className={classes.container}>
      <Card raised={true} className={classes.budgetCard}>
        <CardContent className={classes.budget_content}>
          <label className={classes.budget_title}>YOUR BUDGET</label>
          <input
            type="text"
            min="100"
            className={classes.budget_input}
            placeholder="$10,000.00"
            onChange={inputBudget}
            value={budget}
          />
          {!toggleBudget ? (
            <button
              type="button"
              className={classes.budget_button}
              onClick={calculateBudget}
              disabled={invalidBugdet()}
            >
              Calculate
            </button>
          ) : (
            <BudgetCalculator budget={budget} service={category} />
          )}
        </CardContent>
      </Card>
    </div>
  );
};

export default Budget;
