import React, { useEffect, useState } from "react";
import { Box, Button, Modal } from "@mui/material";
import { Button as Btn, Typography } from "@material-ui/core";
import { PrimaryColor } from "../../../shared/styles/colors";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "../../../assets/admin/edit-icon.svg";
import DeleteModal from "../../modal/DeleteModal";
import EmployeeModel from "../../../model/employee";
import ProjectModel from "../../../model/project";
import Employee from "../../../model/employee";
import EmployeeRole from "../../../model/employeeRole";
import useStyles from "./styles";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50rem",
  bgcolor: "background.paper",
  border: "none",
  borderRadius: "8px",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
  maxHeight: "90vh", // Restrict maximum height to 80% of the viewport height
  overflowY: "auto", // Enable vertical scrolling
  "@media (max-width: 640px)": {
    width: "88%",
  },
};

type Member = {
  id: number;
  employeeId: number;
  employeeRoleId: number;
  Employee: Employee;
  EmployeeRole: EmployeeRole;
};

const TeamModal = ({
  team,
  employees,
  projects,
  roles,
  teamMembers,
  handleCreate,
  handleUpdate,
  handleDelete,
}: any) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const edit = team != null;
  const id = edit ? team.id : 0;
  const [name, setName] = useState(edit ? team.name : null);
  const [description, setDescription] = useState(
    edit ? team.description : null
  );
  const [userId, setUserId] = useState(
    edit ? team.userId : localStorage.getItem("userId")
  );
  const [projectId, setProjectId] = useState(edit ? team.projectId : 0);
  const [members, setMembers] = useState<Member[]>([]);

  useEffect(() => {
    if (edit && teamMembers.length > 0) {
      setMembers(teamMembers);
    }
  }, [open]);

  const handleTeamName = (e: any) => {
    setName(e.target.value);
  };

  const handleTeamDescription = (e: any) => {
    setDescription(e.target.value);
  };

  const handleProjectId = (e: any) => {
    setProjectId(parseInt(e.target.value));
  };

  const handleEmployeeName = (e: any, index: number) => {
    const values = [...members];
    values[index].employeeId = parseInt(e.target.value);
    setMembers(values);
  };

  const handleEmployeeRole = (e: any, index: number) => {
    const values = [...members];
    values[index].employeeRoleId = parseInt(e.target.value);
    setMembers(values);
  };

  const handleAddItem = () => {
    if (members.length > 0 && members[members.length - 1].employeeId === 0)
      return;

    setMembers([
      ...members,
      {
        id: members.length + 1,
        employeeId: 0,
        employeeRoleId: 0,
        Employee: {} as Employee,
        EmployeeRole: {} as EmployeeRole,
      },
    ]);
  };

  const handleDeleteItem = (index: number) => {
    const values = [...members];
    values.splice(index, 1);
    setMembers(values);
  };

  const invalidInput = (): boolean => {
    if (members.length > 0)
      if (
        members[members.length - 1].employeeId === 0 ||
        members[members.length - 1].employeeRoleId === 0
      )
        return true;

    return (
      name === "" || name === null || description === "" || description === null
    );
  };

  const handleSave = () => {
    if (invalidInput()) return;

    const payload = {
      id: id,
      name: name,
      description: description,
      userId: userId,
      projectId: projectId,
      employees: members,
    };

    if (edit) {
      handleUpdate(payload);
    } else {
      handleCreate(payload);
    }

    setName("");
    setDescription("");
    setUserId(null);
    setProjectId(null);
    setMembers([]);
    handleClose();
  };

  return (
    <div>
      {edit ? (
        <Button onClick={handleOpen}>
          <img src={EditIcon} alt="edit icon" />
        </Button>
      ) : (
        <Btn
          className={classes.headerButton}
          variant="contained"
          onClick={handleOpen}
        >
          + Add Team
        </Btn>
      )}
      {open && (
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{ overflowY: "auto" }}
        >
          <Box sx={style}>
            <div className={classes.headerDiv}>
              {edit ? (
                <Typography className={classes.title}>
                  <div className={classes.headerSubDiv2}>
                    <Typography className={classes.title}>Team</Typography>
                    <div className="vl" style={{ marginLeft: "1rem" }}></div>
                    <Typography
                      className={classes.subtitle}
                      style={{ marginLeft: "1rem" }}
                    >
                      Edit
                    </Typography>
                    <DeleteModal item={team} handleDelete={handleDelete} />
                  </div>
                </Typography>
              ) : (
                <div className={classes.headerSubDiv}>
                  <Typography className={classes.title}>Team</Typography>
                  <div className="vl"></div>
                  <Typography className={classes.subtitle}>
                    {edit ? "Edit" : "Setup"}
                  </Typography>
                </div>
              )}
              <Btn onClick={handleClose}>
                <CloseIcon />
              </Btn>
            </div>
            <hr />
            <form className={classes.form}>
              <div className={classes.inputContainer}>
                <Typography className={classes.label}>Name</Typography>
                <input
                  className={classes.input}
                  value={name}
                  onChange={handleTeamName}
                  placeholder="Type the name of the team"
                />
              </div>
              <div className={classes.inputContainer}>
                <Typography className={classes.label}>Description</Typography>
                <textarea
                  className={classes.textarea}
                  value={description}
                  onChange={handleTeamDescription}
                  placeholder="Add description here..."
                />
              </div>
              <div className={classes.inputContainer}>
                <Typography className={classes.label}>
                  Project Association
                </Typography>
                <select
                  className={classes.input}
                  value={projectId}
                  onChange={handleProjectId}
                >
                  <option value={0} disabled>
                    Select project
                  </option>
                  {projects.map((project: ProjectModel) => (
                    <option key={project.id} value={project.id}>
                      {project.name}
                    </option>
                  ))}
                </select>
              </div>
              {!edit && (
                <div className={classes.inputContainer}>
                  <Typography className={classes.label}>Members</Typography>
                  <Typography className={classes.text}>
                    Please choose the name of the employees you wish to add to
                    your team, and specify the roles they will assume.
                  </Typography>
                </div>
              )}
              <div className={classes.inputContainer}>
                <Typography className={classes.label}>Employees</Typography>
                {members.length > 0 &&
                  members.map((member, index) => (
                    <tr key={member.id}>
                      <td>
                        <div className={classes.inputContainer}>
                          <select
                            className={classes.input}
                            value={members[index].employeeId}
                            onChange={(e) => handleEmployeeName(e, index)}
                          >
                            <option value={0} disabled>
                              Select employee
                            </option>
                            {employees.map((emp: EmployeeModel) => (
                              <option key={emp.id} value={emp.id}>
                                {emp.firstName} {emp.lastName}
                              </option>
                            ))}
                          </select>
                        </div>
                      </td>
                      <td>
                        <select
                          className={classes.input}
                          value={members[index].employeeRoleId}
                          onChange={(e) => handleEmployeeRole(e, index)}
                        >
                          <option value={0} disabled>
                            Select employee role
                          </option>
                          {roles.map((role: any) => (
                            <option key={role.id} value={role.id}>
                              {role.name}
                            </option>
                          ))}
                        </select>
                      </td>
                      <td>
                        <Btn
                          className="delete-btn"
                          onClick={() => handleDeleteItem(index)}
                        >
                          🗑️ Remove
                        </Btn>
                      </td>
                    </tr>
                  ))}
                <tr>
                  <td colSpan={3} className="add-item">
                    <span
                      onClick={handleAddItem}
                      style={{ color: PrimaryColor }}
                    >
                      + Add Employee
                    </span>
                  </td>
                </tr>
              </div>
            </form>
            <div className={classes.buttonDiv}>
              <Btn
                className={classes.button}
                onClick={handleSave}
                disabled={invalidInput()}
              >
                {edit ? "Save" : "Create"}
              </Btn>
            </div>
          </Box>
        </Modal>
      )}
    </div>
  );
};

export default TeamModal;
