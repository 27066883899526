import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ListServices, CategoryInGallery } from "../index";
import { ref, getDownloadURL, listAll } from "firebase/storage";
import { storage } from "../../firebase";
import Pathnames from "../../model/enum/Pathnames";
import PainterImg from "../../assets/presentation.png";
import useStyles from "./styles";
import { Typography } from "@material-ui/core";
import { Helmet } from "react-helmet-async";
import { Skeleton } from "@mui/material";

const HOMEPAGE: string = "Homepage";

const Homepage = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [imagesUrl, setImagesUrl] = useState<string[]>([]);
  const listRef = ref(storage, HOMEPAGE);

  useEffect(() => {
    const fetch = async () => {
      await listAll(listRef).then((res) => {
        res.items.forEach((itemRef) => {
          // All the items under listRef.
          getDownloadURL(itemRef).then((x: string) => {
            setImagesUrl((data) => [...data, x]);
          });
        });
      });
    };

    if (imagesUrl.length <= 0) fetch();
  });

  return (
    <>
      <Helmet>
        <title>BATHROOM & KITCHEN REMODELING | SETUP REMODELING INC</title>
        <meta
          name="description"
          content="Top-rated general contractor serving Massachusetts and Rhode Island. 
                Specializing in bathroom remodeling, kitchen remodeling. Setup Remodeling"
        ></meta>
        <link rel="canonical" href="/" />
      </Helmet>
      <main className={classes.homepage}>
        {/* Display the carrousel of kitchen and bathroom */}
        <div className={classes.carrouselBackground}></div>
        {imagesUrl.length > 0 ? (
          <div className={classes.carrouselDiv}>
            <CategoryInGallery
              autoPlay={true}
              showArrows={true}
              showIndicators={true}
              data={imagesUrl}
            />
          </div>
        ) : (
          <Skeleton variant="rectangular" height={"100vh"} width={"100%"} />
        )}

        <div className={classes.requestDiv}>
          <Typography className={classes.requestService}>
            TRANSFORM YOUR HOME WITH SETUPREMODELING
          </Typography>
          <button
            type="button"
            className={classes.request_button3}
            onClick={() => navigate(Pathnames.REQUEST)}
          >
            REQUEST A SERVICE
          </button>
        </div>

        {/* Display the request div */}
        <div className={classes.request_div}>
          <div>
            <label className={classes.request_text}>
              Do you want to hire a quality service?
            </label>
          </div>
          <div className={classes.request_img_and_background_div}>
            <div className={classes.request_color_bgd}></div>
            <img
              src={PainterImg}
              className={classes.request_img}
              alt="request background img"
              loading="lazy"
            ></img>
          </div>
          <div className={classes.request_text_div}>
            <Typography className={classes.text}>
              Elevate Your Home with Unparalleled Remodeling Solutions
            </Typography>
            <div className={classes.request_button_group}>
              <button
                type="button"
                className={classes.request_button}
                onClick={() => navigate(Pathnames.GALLERY)}
              >
                VISIT GALLERY
              </button>
              <button
                type="button"
                className={classes.request_button2}
                onClick={() => navigate(Pathnames.APPOINTMENT)}
              >
                SCHEDULE APPOINTMENT
              </button>
            </div>
          </div>
        </div>
        {/* Display the services */}
        <ListServices />
      </main>
      {/* <Cookies /> */}
    </>
  );
};

export default Homepage;
