import React, { useEffect, useState } from "react";
import { Box, Button, Modal } from "@mui/material";
import { Button as Btn, Typography } from "@material-ui/core";
import { Spinner } from "../../../components";
import ApiService from "../../../service/ApiService";
import AlertService from "../../../service/AlertService";
import FirebaseService from "../../../service/FirebaseService";
import DatabaseEntities from "../../../model/enum/DatabaseEntities";
import DeleteModal from "../../modal/DeleteModal";
import ProductModel from "../../../model/product";
import CategoryModel from "../../../model/category";
import CategoryModal from "../category/CategoryModal";
import VendorModal from "../vendor/VendorModal";
import VendorModel from "../../../model/vendor";
import ToastMessages from "../../../model/enum/ToastMessages";
import CloseIcon from "@mui/icons-material/Close";
import UploadIcon from "../../../assets/admin/upload-icon.svg";
import EditIcon from "../../../assets/admin/edit-icon.svg";
import useStyles from "./styles";
import Pathnames from "../../../model/enum/Pathnames";
import { useNavigate } from "react-router-dom";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "40rem",
  bgcolor: "background.paper",
  border: "none",
  borderRadius: "8px",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
  maxHeight: "90vh", // Restrict maximum height to 80% of the viewport height
  overflowY: "auto", // Enable vertical scrolling
  "@media (max-width: 640px)": {
    width: "80%",
  },
};

const ProductModal = ({
  product,
  isProduct = true,
  handleUpdate,
  handleDelete,
}: any) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const navigate = useNavigate();

  const edit = product != null;
  const [name, setName] = useState(edit ? product.name : null);
  const [description, setDescription] = useState(
    edit ? product.description : null
  );
  const [price, setPrice] = useState(edit ? product.price : null);
  const [cost, setCost] = useState(edit && product.cost ? product.cost : null);
  const [vendorId, setVendorId] = useState(edit ? product.vendorId : null);
  const [vendors, setVendors] = useState<VendorModel[]>([]);
  const [categoryId, setCategoryId] = useState(
    edit ? product.categoryId : null
  );
  const [categories, setCategories] = useState<CategoryModel[]>([]);
  const [file, setFile] = useState<File | undefined>(undefined);
  const [fileName, setFileName] = useState("");
  const imageUrl = edit ? product.imageUrl : null;
  const [toggleSpinner, setToggleSpinner] = useState(false);
  const [toggleVendor, setToggleVendor] = useState(
    edit && product.vendorId ? true : false
  );

  useEffect(() => {
    const fetchCategories = async () => {
      await ApiService.get<CategoryModel[]>(DatabaseEntities.CATEGORIES).then(
        (data: CategoryModel[]) => {
          setCategories(data);
        }
      );
    };

    const fetchVendors = async () => {
      await ApiService.get<VendorModel[]>(DatabaseEntities.VENDORS).then(
        (data: VendorModel[]) => {
          setVendors(data);
        }
      );
    };

    if (open && categories.length <= 0) fetchCategories();
    if (open && vendors.length <= 0) fetchVendors();
  }, [categories, vendors, open]);

  const handleName = (e: any) => {
    setName(e.target.value);
  };

  const handleDescription = (e: any) => {
    setDescription(e.target.value);
  };

  const handlePrice = (e: any) => {
    setPrice(e.target.value);
  };

  const handleCost = (e: any) => {
    setCost(e.target.value);
  };

  const handleVendor = (e: any) => {
    setVendorId(e.target.value);
  };

  const handleCategory = (e: any) => {
    setCategoryId(e.target.value);
  };

  const handleFileChange = (e: any) => {
    setFile(e.target.files[0]);
    setFileName(e.target.files[0].name);
  };

  const handleToggleVendor = () => {
    setToggleVendor(!toggleVendor);
  };

  const submit = async () => {
    if (invalidInput()) return;

    setToggleSpinner(true);

    const payload: ProductModel = {
      id: edit ? product.id : null,
      name: name,
      price: price,
      isProduct: isProduct,
      description: description,
      categoryId: categoryId,
      vendorId: vendorId,
      imageUrl: imageUrl,
    };

    if (cost != null) payload.cost = cost;

    if (edit) {
      await handleUpdate(payload);
    } else {
      if (file) {
        await FirebaseService.uploadFile(file, fileName, "Product-Service")
          .then(async (url: string) => {
            payload.imageUrl = url;
            await handleCreate(payload);
          })
          .catch(() => {
            AlertService.error(ToastMessages.FAILED_UPLOAD);
          });
      } else {
        await handleCreate(payload);
      }
    }

    setToggleSpinner(false);
    setName("");
    setPrice("$0.00");
    setCost("$0.00");
    setDescription("");
    setCategoryId("");
    setVendorId("");
    setFile(undefined);
    setFileName("");

    setTimeout(() => {
      navigate(Pathnames.PRODUCTS_SERVICES_LIST);
    }, 3000);
    handleClose();
  };

  const invalidInput = () => {
    return (
      name === "" ||
      price === "$0.00" ||
      categoryId === "" ||
      description === ""
    );
  };

  const handleCreate = async (payload: any) => {
    await ApiService.post<ProductModel>(DatabaseEntities.PRODUCTS, payload)
      .then(() => {
        setToggleSpinner(true);
        AlertService.succes(ToastMessages.CREATED);
      })
      .catch(() => {
        AlertService.error(ToastMessages.CREATED_FAILED);
      });
  };

  return (
    <div>
      {edit ? (
        <Button onClick={handleOpen}>
          <img src={EditIcon} alt="edit icon" />
        </Button>
      ) : (
        <div style={{ cursor: "pointer" }} onClick={handleOpen}>
          <Typography className={classes.subTitle}>
            {isProduct ? "Product" : "Service"}
          </Typography>
          {isProduct ? (
            <Typography>
              This modal allows you to add a new product to the system, defining
              its details and attributes for proper management and availability.
            </Typography>
          ) : (
            <Typography>
              This modal allows you to add a new service to the system,
              specifying its details and attributes for accurate management and
              availability.
            </Typography>
          )}
        </div>
      )}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ overflowY: "auto" }}
      >
        {toggleSpinner ? (
          <Spinner />
        ) : (
          <Box sx={style}>
            <div className={classes.headerDiv}>
              {edit ? (
                <Typography className={classes.title}>
                  <DeleteModal item={product} handleDelete={handleDelete} />
                </Typography>
              ) : (
                <Typography className={classes.title}>
                  Add {isProduct ? "Product" : "Service"}
                </Typography>
              )}
              <Button onClick={handleClose} sx={{ color: "black" }}>
                <CloseIcon />
              </Button>
            </div>
            <hr />
            <form className={classes.form}>
              <Typography className={classes.label}>
                Name <span style={{ color: "red" }}>*</span>
              </Typography>
              <input
                className={classes.input}
                value={name}
                onChange={handleName}
                placeholder="Label..."
              />
              <Typography className={classes.label}>
                Category <span style={{ color: "red" }}>*</span>
              </Typography>
              <div className={classes.inputGroup}>
                <select
                  className={classes.input}
                  onChange={handleCategory}
                  value={categoryId}
                >
                  <option value="">Select category</option>
                  {categories.map((cat: CategoryModel) => {
                    return (
                      <option key={cat.id} value={cat.id}>
                        {cat.name}
                      </option>
                    );
                  })}
                </select>
                <CategoryModal
                  externalService={true}
                  resetList={() => setCategories([])}
                  setCategoryId={(id: number) => setCategoryId(id)}
                />
              </div>
              <Typography className={classes.label}>
                Description <span style={{ color: "red" }}>*</span>
              </Typography>
              <textarea
                className={classes.textarea}
                name="description"
                placeholder="Add description..."
                onChange={handleDescription}
                defaultValue={description}
              />
              <Typography className={classes.label}>
                Price <span style={{ color: "red" }}>*</span>
              </Typography>
              <input
                className={classes.input}
                value={price}
                type="number"
                min={0}
                onChange={handlePrice}
                placeholder="$0.0"
              />
              {!edit && (
                <>
                  <div className={classes.inputContainer}>
                    <Typography className={classes.label}>Image</Typography>
                  </div>
                  <div className={classes.uploadInput}>
                    <input
                      type="file"
                      id="add-single-img"
                      accept="image/*"
                      className="d-none"
                      onChange={handleFileChange}
                    />
                    <label id="image-label" htmlFor="add-single-img">
                      <img
                        src={UploadIcon}
                        className={classes.image}
                        alt="upload icon"
                      />
                      <label>Drop files to upload or browse</label>
                      <Typography>{fileName}</Typography>
                    </label>
                  </div>
                </>
              )}
              <div className={classes.inputContainer}>
                <Typography className={classes.label}>
                  Purchasing information
                </Typography>
              </div>
              <div className={classes.inputGroup}>
                <input
                  type="checkbox"
                  className={classes.checkbox}
                  checked={toggleVendor}
                  onChange={handleToggleVendor}
                />
                <Typography>Add vendor</Typography>
              </div>
              {toggleVendor && (
                <>
                  <div className={classes.inputContainer}>
                    <Typography className={classes.label}>
                      Cost <span style={{ color: "red" }}>*</span>
                    </Typography>
                  </div>
                  <input
                    className={classes.input}
                    value={cost}
                    type="number"
                    min={0}
                    onChange={handleCost}
                    placeholder="$0.0"
                  />
                  <div className={classes.inputContainer}>
                    <Typography className={classes.label}>Vendor</Typography>
                  </div>
                  <div className={classes.inputGroup}>
                    <select
                      className={classes.input}
                      onChange={handleVendor}
                      value={vendorId}
                    >
                      <option value="">Select vendor</option>
                      {vendors.map((vendor: VendorModel) => {
                        return (
                          <option key={vendor.id} value={vendor.id}>
                            {vendor.name}
                          </option>
                        );
                      })}
                    </select>
                    <VendorModal
                      externalService={true}
                      resetList={() => setVendors([])}
                      setVendorId={(id: number) => setVendorId(id)}
                    />
                  </div>
                </>
              )}
            </form>
            <div className={classes.buttonDiv}>
              <Btn
                className={classes.button}
                onClick={submit}
                disabled={invalidInput()}
              >
                Save
              </Btn>
            </div>
          </Box>
        )}
      </Modal>
    </div>
  );
};

export default ProductModal;
