import React from "react";
import logoGif from "../../../assets/Setup_gif.gif"; // Adjust path accordingly
import './Spinner.css';

const Spinner = () => {
  return (
    <div className="spinner-div">
      <img src={logoGif} className="animated-logo" alt="Animated Logo" />
    </div>
  );
};

export default Spinner;
