import React, { useState, useEffect, ChangeEvent } from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import ClientInfoModal from "../client/ClientInfoModal";
import TransformDate from "../../utils/TransformDate";
import {
  PrimaryColor,
  TableBodyGrey,
  TableHeaderGrey,
} from "../../shared/styles/colors";
import { Spinner } from "../index";
import Status from "../../model/enum/StatusClass";
import MessageModel from "../../model/message";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Card, CardContent, Typography, Button } from "@material-ui/core";
import { TablePagination } from "@mui/material";
import DatabaseEntities from "../../model/enum/DatabaseEntities";
import AlertService from "../../service/AlertService";
import ApiService from "../../service/ApiService";
import ToastMessages from "../../model/enum/ToastMessages";
import { ToastContainer } from "react-toastify";
import ContactDetailModal from "./ContactDetailModal";
import CachedIcon from "@mui/icons-material/Cached";
import useStyles from "../styles/cardStyles";

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: TableHeaderGrey,
    color: PrimaryColor,
    fontWeight: 700,
    whiteSpace: "nowrap",
    zIndex: 0,
    position: "static",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    color: TableBodyGrey,
    fontFamily: "monospace",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const Contacts = () => {
  const classes = useStyles();
  const [contacts, setContacts] = useState<MessageModel[]>([]);
  const [items, setItems] = useState<MessageModel[]>([]);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [toggleSpinner, setToggleSpinner] = useState(true);

  //Get all contacts from the database
  useEffect(() => {
    const fetchData = async () => {
      await ApiService.get<MessageModel[]>(DatabaseEntities.MESSAGES).then(
        (data: MessageModel[]) => {
          if (data.length === 0) return;

          setContacts(data);
          setItems(data.slice(0, rowsPerPage));
        }
      );
      setToggleSpinner(false);
    };

    if (toggleSpinner && contacts.length === 0) fetchData();
  }, [contacts]);

  const handleUpdate = async (payload: any, notify: boolean = true) => {
    await ApiService.put<MessageModel>(
      `${DatabaseEntities.MESSAGES}/${payload.id}`,
      payload
    )
      .then(() => {
        if (notify) AlertService.succes(ToastMessages.UPDATED);
        resetList();
      })
      .catch(() => {
        AlertService.error(ToastMessages.UPDATE_FAILED);
      });
  };

  const resetList = (): void => {
    setContacts([]);
    setItems([]);
    setPage(0);
    setToggleSpinner(true);
  };

  const handleDelete = async (payload: any, notify: boolean = true) => {
    await ApiService.delete<any>(`${DatabaseEntities.MESSAGES}/${payload.id}`)
      .then(() => {
        if (notify) AlertService.succes(ToastMessages.DELETED);
        resetList();
      })
      .catch(() => {
        AlertService.error(ToastMessages.DELETE_FAILED);
      });
  };

  const handlePageChange = (
    event: ChangeEvent<unknown> | null,
    pg: number,
    itemsPerPage: number = rowsPerPage
  ) => {
    setToggleSpinner(true);
    setPage(pg);
    let MaxElem = pg * itemsPerPage;

    items.forEach((_itm: MessageModel, index) => {
      items.splice(index);
    });

    for (let i = MaxElem; i < MaxElem + itemsPerPage; i++) {
      if (i < contacts.length) items.push(contacts[i]);
    }

    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    setToggleSpinner(false);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    let pg = parseInt(event.target.value);
    setRowsPerPage((rowsPerPage) => pg);
    setPage((page) => 0);
    handlePageChange(null, 0, pg);
  };

  return (
    <>
      <Card raised className={classes.card}>
        <div className={classes.headerContainer}>
          <Typography className={classes.title}>Contact Messages</Typography>
          <Button onClick={resetList}>
            <CachedIcon />
          </Button>
        </div>
        <CardContent>
          <Paper>
            <TableContainer>
              <Table
                stickyHeader
                aria-label="customized table"
                padding="normal"
                size="medium"
              >
                <TableHead>
                  <TableRow>
                    <StyledTableCell align="right">Client</StyledTableCell>
                    <StyledTableCell align="right">Message</StyledTableCell>
                    <StyledTableCell align="right">Created At</StyledTableCell>
                    <StyledTableCell align="right">Status</StyledTableCell>
                    <StyledTableCell align="center">
                      <MoreVertIcon />
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                {toggleSpinner ? (
                  <Spinner />
                ) : (
                  <TableBody>
                    {items.length > 0 ? (
                      items.map((row: MessageModel) => (
                        <StyledTableRow key={row.id}>
                          <StyledTableCell align="right">
                            <ClientInfoModal clientData={row.Client} />
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            <span
                              className={classes.limitedText}
                              style={{ width: 600 }}
                            >
                              {row.text}
                            </span>
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            {TransformDate.toFullDateHours(row.createdAt)}
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            <Typography
                              style={Status.mapStatusColor(row.Status?.name)}
                            >
                              {" "}
                              <div
                                style={Status.mapStatusRoundedColor(
                                  row.Status?.name
                                )}
                              ></div>
                              {row.Status?.name}
                            </Typography>
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            <ContactDetailModal
                              contact={row}
                              handleUpdate={handleUpdate}
                              handleDelete={handleDelete}
                            />
                          </StyledTableCell>
                        </StyledTableRow>
                      ))
                    ) : (
                      <StyledTableCell align="right">No items</StyledTableCell>
                    )}
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Paper>
          <TablePagination
            component="div"
            count={contacts.length}
            page={page}
            onPageChange={handlePageChange}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </CardContent>
      </Card>
      <ToastContainer />
    </>
  );
};

export default Contacts;
