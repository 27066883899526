import React, { useState, useEffect } from "react";
import { Button, Card, CardContent, Typography } from "@material-ui/core";
import EmailService from "../../service/EmailService";
import AlertService from "../../service/AlertService";
import { ToastContainer } from "react-toastify";
import ContactImg from "../../assets/contact.png";
import useStyles from "./styles";
import ClientModel from "../../model/client";
import ClientServices from "../../model/enum/ClientServices";
import ToastMessages from "../../model/enum/ToastMessages";
import { NumberFormatter } from "react-number-formatter";
import MessageModel from "../../model/message";
import { Helmet } from "react-helmet-async";
import { Spinner } from "../index";
import { gtag_report_conversion } from "../../utils/GoogleReport";
import { useLocation, useNavigate } from "react-router-dom";
import ApiService from "../../service/ApiService";
import Pathnames from "../../model/enum/Pathnames";

const Contact = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [imageLoaded, setImageLoaded] = useState(false);
  const [toggleSpinner, setToggleSpinner] = useState(false);

  const location = useLocation();

  useEffect(() => {
    const img = new Image();
    img.src = ContactImg; // Use the imported image

    // Set the imageLoaded state to true once the image has finished loading
    img.onload = () => {
      setImageLoaded(true);
    };

    // Cleanup function to avoid memory leaks
    return () => {
      img.onload = null;
    };
  });

  const handleFirstName = (e: any) => {
    setFirstName(e.target.value);
  };

  const handleLastName = (e: any) => {
    setLastName(e.target.value);
  };

  const handleEmail = (e: any) => {
    setEmail(e.target.value);
  };

  const handlePhone = (e: any) => {
    setPhone(e);
  };

  const handleMessage = (e: any) => {
    setMessage(e.target.value);
  };

  const invalidInput = () => {
    return (
      firstName === "" ||
      email === "" ||
      phone === "" ||
      message === "" ||
      toggleSpinner
    );
  };

  const handleSubmit = async (e: any) => {
    if (!invalidInput()) {
      setToggleSpinner(true);

      const client: ClientModel = {
        firstName: firstName,
        lastName: lastName,
        email: email,
        phone: phone,
        service: ClientServices.MESSAGE,
      };

      var clientId = 0;

      await ApiService.post<any>("frontend/client", client).then((cl) => {
        if (cl.error) clientId = cl.existingClient.id;
        else clientId = cl.id;
      });

      const payload: MessageModel = {
        text: message,
        clientId: clientId,
      };

      await ApiService.post<any>("frontend/message", payload)
        .then(async (res) => {
          if (res) {
            AlertService.succes(ToastMessages.MESSAGE_SENT);
            //await SmsService.send(phone, "Contact Message"); Disabled to save expends
            await EmailService.sendEmailToUser(
              firstName,
              email,
              "Contact Message"
            ).then(() => {
              setFirstName("");
              setLastName("");
              setEmail("");
              setPhone("");
              setMessage("");
            });
          }
        })
        .catch(() => {
          AlertService.error(ToastMessages.MESSAGE_FAILED_TO_SEND);
        })
        .finally(() => {
          navigate(Pathnames.THANK_YOU_CONTACT);
        });
      setToggleSpinner(false);
    }
  };

  return (
    <>
      <Helmet>
        <title>Contact Us - Setup Remodeling</title>
        <meta
          name="description"
          content="Connect with our team to discuss your remodeling goals and
                  schedule a consultation. Setup Remodeling"
        ></meta>
        <link rel="canonical" href="/contact" />
      </Helmet>
      {imageLoaded ? (
        <div className={classes.container}>
          <Card className={classes.card} raised>
            <CardContent>
              <div className={classes.cardContenDiv}>
                <div className={classes.firstDiv}>
                  <Typography className={classes.title}>
                    Get in touch
                  </Typography>
                  <Typography className={classes.description}>
                    Connect with our team to discuss your remodeling goals and
                    schedule a consultation. Fill the form bellow.
                  </Typography>
                  <div className={classes.inputDiv}>
                    <div className={classes.inputGroup}>
                      <input
                        className={classes.input}
                        type="text"
                        onChange={handleFirstName}
                        value={firstName}
                        name="name"
                        placeholder="Your first name..."
                      />
                      <input
                        className={classes.input}
                        type="text"
                        onChange={handleLastName}
                        value={lastName}
                        name="lastname"
                        placeholder="Your last name..."
                      />
                    </div>
                    <div className={classes.inputGroup}>
                      <input
                        className={classes.input}
                        type="email"
                        onChange={handleEmail}
                        value={email}
                        name="email"
                        placeholder="Your email..."
                      />
                      <div className={classes.input}>
                        <NumberFormatter
                          defaultCountry="USA"
                          placeholder="Your phone..."
                          value={phone}
                          getValue={(n: string) => handlePhone(n)}
                        />
                      </div>
                    </div>
                    <textarea
                      className={classes.textarea}
                      onChange={handleMessage}
                      value={message}
                      name="message"
                      placeholder="Your message..."
                    />
                  </div>
                  <Button
                    className={classes.button}
                    type="submit"
                    disabled={invalidInput()}
                    onClick={handleSubmit}
                  >
                    Send Message
                  </Button>
                </div>
                <div className={classes.secondDiv}>
                  <img
                    src={ContactImg}
                    alt="contact"
                    className={classes.contactImg}
                    loading="lazy"
                  />
                </div>
                <ToastContainer />
              </div>
            </CardContent>
          </Card>
        </div>
      ) : (
        <Spinner />
      )}
    </>
  );
};

export default Contact;
