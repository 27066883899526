import CloseIcon from "@mui/icons-material/Close";
import useStyles from "./styles";

const FullscreenImage = ({ imageUrl, toggleFullScreen, reviewMode }: any) => {
  const classes = useStyles();

  return (
    <div className={classes.mediaDiv}>
      <img
        src={imageUrl}
        className={reviewMode ? classes.mediaReview : classes.media}
        onClick={() => toggleFullScreen("")}
        alt="fullscreen"
      />
      <CloseIcon
        className={reviewMode ? classes.closeIconReview : classes.closeIcon}
        onClick={() => toggleFullScreen("")}
      />
    </div>
  );
};

export default FullscreenImage;
