import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { AppBar, Button, Toolbar, Typography } from "@material-ui/core";
import { useScrollTrigger, Box, Fab, Fade } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import logo from "../../../assets/main.png";
import useStyles from "./styles";
import Pathnames from "../../../model/enum/Pathnames";
import PhoneIcon from "../../../assets/admin/phone2-icon.svg";

interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window;
  children: React.ReactElement;
}

function ScrollTop(props: Props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    const anchor = (
      (event.target as HTMLDivElement).ownerDocument || document
    ).querySelector("#back-to-top-anchor");

    if (anchor) {
      anchor.scrollIntoView({
        block: "center",
      });
    }
  };

  return (
    <Fade in={trigger}>
      <Box
        onClick={handleClick}
        role="presentation"
        sx={{ position: "fixed", bottom: 16, right: 16 }}
      >
        {children}
      </Box>
    </Fade>
  );
}

function FloatingIcon() {
  const classes = useStyles();

  //mkt phone (401) 203-3711
  //kitchen&bathroom (855) 777-3887
  //setup (800) 530 4099
  return (
    <>
      <button className={classes.phone_button}>
        <a href="tel:+1-800-530-4099">
          <img
            src={PhoneIcon}
            alt="caller-icon"
            className={classes.phone_img}
          />
        </a>
      </button>
    </>
  );
}

function FloatingNumber() {
  const classes = useStyles();
  const location = useLocation();
  let pathname = location.pathname;

  return (
    <>
      {pathname === Pathnames.HOMEPAGE && (
        <div className={classes.divPhone}>
          {/* <img src={PhoneIcon} alt="caller-icon" className={classes.phone_img} /> */}
          <a href="tel:+1-800-530-4099">
            <Typography className={classes.phone_text}>
              (800)-530-4099
            </Typography>
          </a>
        </div>
      )}
    </>
  );
}

const Navbar = () => {
  const classes = useStyles();
  const location = useLocation();
  const [toggleMenu, setToggleMenu] = useState(false);
  const navigate = useNavigate();

  let pathname = location.pathname;

  const checkLocation = (loc: any) => {
    return pathname === loc;
  };

  const navbarAllowedPaths = (): boolean => {
    return (
      pathname === Pathnames.HOMEPAGE ||
      pathname === Pathnames.REQUEST ||
      pathname === Pathnames.GALLERY ||
      pathname === Pathnames.REVIEWS ||
      pathname === Pathnames.ABOUT ||
      pathname === Pathnames.CONTACT ||
      pathname === Pathnames.TERMS ||
      pathname === Pathnames.PRIVACY ||
      pathname === Pathnames.APPOINTMENT ||
      pathname === Pathnames.FINANCE ||
      pathname === Pathnames.THANK_YOU_APPOINTMENT ||
      pathname === Pathnames.THANK_YOU_REQUEST ||
      pathname === Pathnames.THANK_YOU_CONTACT ||
      pathname.includes(Pathnames.SERVICE)
    );
  };

  useEffect(() => {
    scrollToTop();
  });

  const scrollToTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  const toggleMenuHandler = (condition: boolean) => {
    setToggleMenu((_toggleMenu) => condition);
  };

  const Menu = () => {
    return (
      <>
        <Typography
          className={
            checkLocation(Pathnames.REQUEST)
              ? classes.textClicked
              : classes.text
          }
          component={Link}
          to={Pathnames.REQUEST}
          variant="h6"
          onClick={() => toggleMenuHandler(false)}
        >
          REQUEST SERVICE
        </Typography>
        <Typography
          className={
            checkLocation(Pathnames.REVIEWS)
              ? classes.textClicked
              : classes.text
          }
          component={Link}
          to={Pathnames.REVIEWS}
          variant="h6"
          onClick={() => toggleMenuHandler(false)}
        >
          REVIEWS
        </Typography>
        <Typography
          className={
            checkLocation(Pathnames.FINANCE)
              ? classes.textClicked
              : classes.text
          }
          component={Link}
          to={Pathnames.FINANCE}
          variant="h6"
          onClick={() => toggleMenuHandler(false)}
        >
          FINANCE
        </Typography>
        <Typography
          className={
            checkLocation(Pathnames.ABOUT) ? classes.textClicked : classes.text
          }
          component={Link}
          to={Pathnames.ABOUT}
          variant="h6"
          onClick={() => toggleMenuHandler(false)}
        >
          ABOUT
        </Typography>
        <Typography
          className={
            checkLocation(Pathnames.CONTACT)
              ? classes.textClicked
              : classes.text
          }
          component={Link}
          to={Pathnames.CONTACT}
          variant="h6"
          onClick={() => toggleMenuHandler(false)}
        >
          CONTACT
        </Typography>
      </>
    );
  };

  return (
    <div>
      {navbarAllowedPaths() && (
        <>
          <AppBar className={classes.appBar}>
            <Button
              type="button"
              className={
                checkLocation(Pathnames.APPOINTMENT)
                  ? classes.appointment_buttonClicked
                  : classes.appointment_button
              }
              component={Link}
              to={Pathnames.APPOINTMENT}
              onClick={() => navigate(Pathnames.APPOINTMENT)}
            >
              SCHEDULE APPOINTMENT
            </Button>
            <Toolbar>
              <div className={classes.logoDiv}>
                <Link to={Pathnames.HOMEPAGE}>
                  <img
                    src={logo}
                    className={classes.logo}
                    alt="logo"
                    loading="lazy"
                  />
                </Link>
              </div>
              {toggleMenu ? (
                <>
                  <div className={classes.dropdown}>
                    <Menu />
                  </div>
                  <div className={classes.menuIcon}>
                    <CloseIcon onClick={() => toggleMenuHandler(false)} />
                  </div>
                </>
              ) : (
                <>
                  <div className={classes.textDiv}>
                    <Menu />
                  </div>
                  <div className={classes.menuIcon}>
                    <MenuIcon onClick={() => toggleMenuHandler(true)} />
                  </div>
                </>
              )}
            </Toolbar>
          </AppBar>
          <FloatingNumber />
          <FloatingIcon />
          <ScrollTop>
            <Fab size="small" aria-label="scroll back to top">
              <KeyboardArrowUpIcon onClick={scrollToTop} />
            </Fab>
          </ScrollTop>
        </>
      )}
    </div>
  );
};

export default Navbar;
