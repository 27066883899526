import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Tabs, Tab, Box } from "@mui/material";
import { PrimaryColor } from "../../shared/styles/colors";
import { Card, CardContent, Typography } from "@material-ui/core";
import Employees from "./employees/Employees";
import Teams from "./team/Teams";
import Pathnames from "../../model/enum/Pathnames";
import useStyles from "../styles/cardStyles";

const Overview = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  let pathname = location.pathname;

  const mapValueFromUrl = (): number => {
    if (pathname.split("/")[4] == "employees") return 1;

    return 0;
  };

  const [value, setValue] = useState(mapValueFromUrl()); // Active tab

  const Type = () => {
    switch (value) {
      case 0:
        return <Teams />;
      case 1:
        return <Employees />;
    }

    return <></>;
  };

  const handleChange = (_event: any, newValue: number) => {
    setValue(newValue);
  };

  return (
    <>
      <Card raised className={classes.card}>
        <div className={classes.headerContainer}>
          <Typography className={classes.title}>Overview</Typography>
          {/* <ItemModal /> */}
        </div>
        <CardContent>
          <Box sx={{ width: "50%", borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              centered
              variant="fullWidth"
              textColor="inherit"
              indicatorColor="primary"
              TabIndicatorProps={{
                sx: { height: 2, bgcolor: PrimaryColor }, // Customize the underline
              }}
            >
              <Tab
                label="Teams"
                sx={{
                  opacity: value === 0 ? 1 : 0.5,
                  textTransform: "capitalize",
                }}
                onClick={() => navigate(Pathnames.TEAMS_LIST)}
              />
              <Tab
                label="Employees"
                sx={{
                  opacity: value === 1 ? 1 : 0.5,
                  textTransform: "capitalize",
                }}
                onClick={() => navigate(Pathnames.EMPLOYEES_LIST)}
              />
            </Tabs>
          </Box>
          <Type />
        </CardContent>
      </Card>
    </>
  );
};

export default Overview;
