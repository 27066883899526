import React, { ChangeEvent, useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  PrimaryColor,
  TableBodyGrey,
  TableHeaderGrey,
} from "../../../shared/styles/colors";
import DatabaseEntities from "../../../model/enum/DatabaseEntities";
import { Typography, Button } from "@material-ui/core";
import { TablePagination } from "@mui/material";
import { Spinner } from "../../../components";
import { ToastContainer } from "react-toastify";
import AlertService from "../../../service/AlertService";
import ApiService from "../../../service/ApiService";
import ToastMessages from "../../../model/enum/ToastMessages";
import EmployeeModel from "../../../model/employee";
import EmployeeModal from "./EmployeeModal";
import CachedIcon from "@mui/icons-material/Cached";
import TransformDate from "../../../utils/TransformDate";
import EmployeeDetailModal from "./EmployeeDetailModal";
import useStyles from "../../styles/cardStyles";

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: TableHeaderGrey,
    color: PrimaryColor,
    fontWeight: 700,
    whiteSpace: "nowrap",
    zIndex: 0,
    position: "static",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    color: TableBodyGrey,
    fontFamily: "monospace",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const Employees = () => {
  const classes = useStyles();
  const [employees, setEmployees] = useState<EmployeeModel[]>([]);
  const [items, setItems] = useState<EmployeeModel[]>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [toggleSpinner, setToggleSpinner] = useState(true);

  useEffect(() => {
    const fetchDate = async () => {
      await ApiService.get<EmployeeModel[]>(DatabaseEntities.EMPLOYES).then(
        (data: EmployeeModel[]) => {
          if (data.length === 0) return;

          setEmployees(data);
          setItems(data.slice(0, rowsPerPage));
        }
      );
      setToggleSpinner(false);
    };

    if (toggleSpinner && employees.length === 0) fetchDate();
  }, [employees]);

  //Handle the inserts on the database
  const handleCreate = async (payload: any, notify: boolean = true) => {
    await ApiService.post<EmployeeModel>(DatabaseEntities.EMPLOYES, payload)
      .then(() => {
        if (notify) AlertService.succes(ToastMessages.CREATED);
        resetList();
      })
      .catch(() => {
        AlertService.error(ToastMessages.CREATED_FAILED);
      });
  };

  //Handle the updates on the database
  const handleUpdate = async (payload: any, notify: boolean = true) => {
    await ApiService.put<EmployeeModel>(
      `${DatabaseEntities.EMPLOYES}/${payload.id}`,
      payload
    )
      .then(() => {
        if (notify) AlertService.succes(ToastMessages.UPDATED);
        resetList();
      })
      .catch(() => {
        AlertService.error(ToastMessages.UPDATE_FAILED);
      });
  };

  //Handle the deletes on the database
  const handleDelete = async (payload: any, notify: boolean = true) => {
    await ApiService.delete<any>(`${DatabaseEntities.EMPLOYES}/${payload.id}`)
      .then(() => {
        if (notify) AlertService.succes(ToastMessages.DELETED);
        resetList();
      })
      .catch(() => {
        AlertService.error(ToastMessages.DELETE_FAILED);
      });
  };

  const resetList = (): void => {
    setEmployees([]);
    setItems([]);
    setPage(0);
    setToggleSpinner(true);
  };

  const handlePageChange = (
    event: ChangeEvent<unknown> | null,
    pg: number,
    itemsPerPage: number = rowsPerPage
  ) => {
    setToggleSpinner(true);
    setPage(pg);
    let MaxElem = pg * itemsPerPage;

    items.forEach((_itm: any, index) => {
      items.splice(index);
    });

    for (let i = MaxElem; i < MaxElem + itemsPerPage; i++) {
      if (i < employees.length) items.push(employees[i]);
    }

    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    setToggleSpinner(false);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    let pg = parseInt(event.target.value);
    setRowsPerPage((rowsPerPage) => pg);
    setPage((page) => 0);
    handlePageChange(null, 0, pg);
  };

  return (
    <>
      <div className={classes.headerContainer}>
        <Typography className={classes.title}></Typography>
        <div className={classes.headerDiv}>
          <EmployeeModal handleCreate={handleCreate} />
          <Button onClick={resetList}>
            <CachedIcon />
          </Button>
        </div>
      </div>
      <Paper sx={{ marginTop: "20px" }}>
        <TableContainer>
          <Table
            stickyHeader
            aria-label="customized table"
            padding="normal"
            size="medium"
          >
            <TableHead>
              <TableRow>
                <StyledTableCell align="right">Id</StyledTableCell>
                <StyledTableCell align="right">Name</StyledTableCell>
                <StyledTableCell align="right">Email</StyledTableCell>
                <StyledTableCell align="right">Phone</StyledTableCell>
                <StyledTableCell align="right">Rating</StyledTableCell>
                <StyledTableCell align="right">Created At</StyledTableCell>
                <StyledTableCell align="right">
                  <MoreVertIcon />
                </StyledTableCell>
              </TableRow>
            </TableHead>
            {toggleSpinner ? (
              <Spinner />
            ) : (
              <TableBody>
                {items.length > 0 ? (
                  items.map((row: EmployeeModel) => (
                    <StyledTableRow key={row.id}>
                      <StyledTableCell align="right">{row.id}</StyledTableCell>
                      <StyledTableCell align="right">
                        {row.firstName} {row.lastName}
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        {row.email}
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        {row.phone}
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        {row.rating}
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        {TransformDate.toFullDate(row.createdAt)}
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        <EmployeeDetailModal
                          employee={row}
                          handleUpdate={handleUpdate}
                          handleDelete={handleDelete}
                        />
                      </StyledTableCell>
                    </StyledTableRow>
                  ))
                ) : (
                  <StyledTableCell align="left">No items</StyledTableCell>
                )}
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </Paper>
      <TablePagination
        component="div"
        count={employees.length}
        page={page}
        onPageChange={handlePageChange}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <ToastContainer />
    </>
  );
};

export default Employees;
