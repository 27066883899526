import { makeStyles } from "@material-ui/core/styles";
import {
  LightPrimaryColor,
  PrimaryColor,
  White,
  LightGrey,
  BackgroundBlue,
  InputColor,
} from "../../shared/styles/colors";

export default makeStyles(() => ({
  appointmentContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    background: BackgroundBlue,
  },
  appointmentCard: {
    width: "1000px !important",
    marginTop: "150px",
    marginBottom: "50px",
    borderRadius: "25px",
    padding: "5rem",
    "@media (max-width: 640px)": {
      width: "90% !important",
      //marginTop: "30%",
      padding: "20px",
      marginTop: "120px",
    },
    // "@media (min-width: 1536px)": {
    //   width: "60% !important",
    // },
  },
  titleDiv: {
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
  },
  title: {
    fontSize: "2rem",
    fontWeight: "bold",
    color: PrimaryColor,
    "@media (max-width: 640px)": {
      fontSize: "1.5rem",
    },
  },
  description: {
    marginTop: "2%",
    textAlign: "start",
  },
  cardContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "3%",
    padding: 0,
    "@media (max-width: 640px)": {
      width: "100% !important",
    },
  },
  formContainer: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    alignItems: "center",
    "@media (max-width: 640px)": {
      width: "100%",
      flexDirection: "column",
    },
  },
  calendarContainer: {
    display: "flex",
    width: "50%",
    alignItems: "center",
    "@media (max-width: 640px)": {
      width: "100%",
      marginBottom: "5%",
    },
  },
  calendar: {
    borderRadius: "10px",
    padding: "3px",
    border: ".1px solid #cfcfcf",
    width: "100%",
    "& div, button": {
      borderRadius: "20px",
      color: PrimaryColor,
      "&:disabled": {
        color: "#cecece",
        background: White,
        cursor: "not-allowed",
      },
    },
  },
  inputGroup: {
    display: "flex",
    flexDirection: "row",
    width: "100% !important",
    alignItems: "center",
    justifyContent: "center",
    padding: 0,
    "@media (max-width: 640px)": {
      flexDirection: "column",
    },
  },
  inputDiv: {
    display: "flex",
    flexDirection: "column",
    width: "60% !important",
    alignItems: "center",
    "@media (max-width: 640px)": {
      width: "100% !important",
    },
  },
  input: {
    width: "95% !important",
    border: "none",
    borderRadius: "5px",
    padding: "0.5rem",
    margin: "0.5rem",
    color: InputColor,
    background: LightGrey,
    "@media (max-width: 640px)": {
      width: "100%",
    },
    "& div, input, button": {
      //child
      color: InputColor,
      border: "none",
    },
  },
  city: {
    color: PrimaryColor,
  },
  textarea: {
    width: "95% !important",
    height: "auto",
    border: "none",
    borderRadius: "5px",
    fontSize: "1rem",
    color: InputColor,
    background: LightGrey,
    padding: "10px",
    maxHeight: "10rem",
    "@media (max-width: 640px)": {
      minHeight: "10rem",
      width: "100%",
      margin: 0,
      padding: 6,
    },
  },
  button: {
    padding: "1% 3% 1% 3%",
    marginTop: "5%",
    width: "12rem",
    color: White,
    background: PrimaryColor,
    borderRadius: "10px",
    textTransform: "none",
    "&:hover": {
      color: PrimaryColor,
    },
    "&:disabled": {
      color: White,
      cursor: "none",
      background: LightPrimaryColor,
    },
    "@media (max-width: 640px)": {
      width: "auto",
      marginTop: "10%",
    },
  },
  error: {
    color: "red",
    fontSize: "0.875rem",
    marginTop: "1%",
  },
}));
