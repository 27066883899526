export default class TransformDate {
  /**
   *
   * @param date
   * @returns
   */
  static toFullDate = (date: any) => {
    if (date === null || date === undefined) return;

    var dateFormat = new Date(date); // Get date from backend formar

    return `${
      dateFormat.getMonth() + 1
    }/${dateFormat.getDate()}/${dateFormat.getFullYear()}`;
  };

  /**
   *
   * @param date
   * @returns
   */
  static toFullDateHours = (date: any) => {
    if (date === null || date === undefined) return;

    var dateFormat = new Date(date); // Get date from backend formar

    return (
      dateFormat.getMonth() +
      1 +
      "/" +
      dateFormat.getDate() +
      "/" +
      dateFormat.getFullYear() +
      " - " +
      dateFormat.toLocaleString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      })
    );
  };

  /**
   *
   * @param date
   * @returns
   */
  static toMinutes = (date: any) => {
    if (date === null || date === undefined) return;

    var dateFormat = new Date(date); // Get date from backend formar

    return dateFormat.getMinutes();
  };

  /**
   *
   * @param date
   * @returns
   */
  static toHours = (date: any) => {
    if (date === null || date === undefined) return;

    var dateFormat = new Date(date); // Get date from backend formar

    return dateFormat.getHours();
  };

  /**
   *
   * @param date
   * @returns
   */
  static toDay = (date: any) => {
    if (date === null || date === undefined) return;

    var dateFormat = new Date(date); // Get date from backend formar

    return dateFormat.getDate();
  };

  /**
   *
   * @param date
   * @returns
   */
  static toMonth = (date: any) => {
    if (date === null || date === undefined) return;

    var dateFormat = new Date(date); // Get date from backend formar

    return dateFormat.getMonth();
  };

  /**
   *
   * @param date
   * @returns
   */
  static toMonthNameAndYear = (date: any) => {
    if (date === null || date === undefined) return;

    var dateFormat = new Date(date); // Get date from backend formar

    var monthName = mapMonth(dateFormat.getMonth());

    return `${monthName}, ${dateFormat.getFullYear()}`;
  };

  /**
   *
   * @param date
   * @returns
   */
  static toMonthNameDateAndYear = (date: any) => {
    if (date === null || date === undefined) return;

    var dateFormat = new Date(date); // Get date from backend formar

    var monthName = mapMonth(dateFormat.getMonth());

    return `${monthName} ${dateFormat.getDate()}, ${dateFormat.getFullYear()}`;
  };

  /**
   *
   * @param date
   * @returns
   */
  static toDayMonthExtend = (date: any) => {
    if (date === null || date === undefined) return;

    var dateFormat = new Date(date); // Get date from backend formar
    return mapDay(dateFormat.getDay()) + ", " + mapMonth(dateFormat.getMonth());
  };

  /**
   *
   * @param date
   * @returns
   */
  static toCompleteDate = (date: any) => {
    if (date === null || date === undefined) return;

    var dateFormat = new Date(date); // Get date from backend formar
    return dateFormat.toDateString();
  };

  // Helper function to convert 24-hour time to 12-hour format
  static convertTo12HourFormat = (time24: string | undefined): string => {
    if (!time24) return "";

    let [hours, minutes] = time24.split(":");
    let period = "AM";

    let hoursNum = parseInt(hours, 10);
    if (hoursNum >= 12) {
      period = "PM";
      if (hoursNum > 12) hoursNum -= 12; // Convert 13:00 to 12-hour format
    }
    if (hoursNum === 0) {
      hoursNum = 12; // Convert 00:00 to 12:00 AM
    }

    return `${hoursNum}:${minutes} ${period}`;
  };
}

/**
 *
 * @param month
 * @returns
 */
const mapMonth = (month: number): string => {
  var name = "";
  switch (month) {
    case 0:
      name = "Jan";
      break;
    case 1:
      name = "Feb";
      break;
    case 2:
      name = "Mar";
      break;
    case 3:
      name = "Apr";
      break;
    case 4:
      name = "May";
      break;
    case 5:
      name = "Jun";
      break;
    case 6:
      name = "Jul";
      break;
    case 7:
      name = "Aug";
      break;
    case 8:
      name = "Set";
      break;
    case 9:
      name = "Oct";
      break;
    case 10:
      name = "Nov";
      break;
    case 11:
      name = "Dec";
      break;
    default:
      break;
  }

  return name;
};

/**
 *
 * @param day
 * @returns
 */
const mapDay = (day: number): string => {
  var name = "";
  switch (day) {
    case 0:
      name = "Sun";
      break;
    case 1:
      name = "Mon";
      break;
    case 2:
      name = "Tue";
      break;
    case 3:
      name = "Wed";
      break;
    case 4:
      name = "Thu";
      break;
    case 5:
      name = "Fri";
      break;
    case 6:
      name = "Sat";
      break;
    default:
      break;
  }

  return name;
};
