import React, { useState } from "react";
import { Box, Button, Modal } from "@mui/material";
import { Typography } from "@material-ui/core";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CloseIcon from "@mui/icons-material/Close";
import CategoryModel from "../../../model/category";
import CategoryModal from "./CategoryModal";
import useStyles from "./styles";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "35rem",
  bgcolor: "background.paper",
  order: "none",
  borderRadius: "8px",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
  maxHeight: "90vh", // Restrict maximum height to 80% of the viewport height
  overflowY: "auto", // Enable vertical scrolling
  "@media (max-width: 640px)": {
    width: "80%",
  },
};

interface Props {
  category: CategoryModel;
  handleUpdate: any;
  handleDelete: any;
}

const CategoryDetailModal = ({
  category,
  handleUpdate,
  handleDelete,
}: Props) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <Button onClick={handleOpen}>
        <MoreVertIcon />
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ overflowY: "auto" }}
      >
        <Box sx={style}>
          <div className={classes.headerDiv}>
            <Typography className={classes.title}>Category details</Typography>
            <div className={classes.headerSubDiv}>
              <CategoryModal
                category={category}
                handleUpdate={handleUpdate}
                handleDelete={handleDelete}
              />
              <Button onClick={handleClose} sx={{ color: "black" }}>
                <CloseIcon />
              </Button>
            </div>
          </div>
          <hr />
          <form className={classes.form}>
            <Typography className={classes.label}>Name</Typography>
            <input
              className={classes.inputDetail}
              value={category.name}
              disabled
            />
          </form>
        </Box>
      </Modal>
    </div>
  );
};

export default CategoryDetailModal;
