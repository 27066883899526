import React, { useEffect, useState } from "react";
import { Box, Button, Modal } from "@mui/material";
import { Button as Btn, Typography } from "@material-ui/core";
import { ToastContainer } from "react-toastify";
import { Spinner } from "../../components";
import { DarkGrey, PrimaryColor } from "../../shared/styles/colors";
import Calendar from "react-calendar";
import FirebaseService from "../../service/FirebaseService";
import AlertService from "../../service/AlertService";
import ApiService from "../../service/ApiService";
import GeocodeService from "../../service/GeocodeService";
import ImageCompressor from "../../utils/ImageCompressor";
import ToastMessages from "../../model/enum/ToastMessages";
import DatabaseEntities from "../../model/enum/DatabaseEntities";
import ServiceModel from "../../model/service";
import ReviewModel from "../../model/review";
import ToggleOnIcon from "@mui/icons-material/ToggleOn";
import ToggleOffIcon from "@mui/icons-material/ToggleOff";
import UploadIcon from "../../assets/admin/upload-icon.svg";
import CloseIcon from "@mui/icons-material/Close";
import useStyles from "../styles/styles";
import "react-calendar/dist/Calendar.css";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50rem",
  bgcolor: "background.paper",
  border: "none",
  borderRadius: "8px",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
  maxHeight: "90vh", // Restrict maximum height to 80% of the viewport height
  overflowY: "auto", // Enable vertical scrolling
  "@media (max-width: 640px)": {
    width: "85%",
  },
};

const PostToReview = ({ handleCreate }: any) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [file, setFile] = useState<File | undefined>(undefined);
  const [fileName, setFileName] = useState("");
  const [serviceId, setServiceId] = useState();
  const [services, setServices] = useState<ServiceModel[]>([]);
  const [description, setDescription] = useState("");
  const [finishedAt, setFinishedAt] = useState(new Date());
  const [location, setLocation] = useState("");
  const [uploading, setUploading] = useState(false);
  const [toggleCompression, setToggleCompression] = useState(false);

  //Get all services from the database
  useEffect(() => {
    const fetchData = async () => {
      await ApiService.get<ServiceModel[]>(DatabaseEntities.SERVICES).then(
        (data) => {
          setServices(data);
        }
      );
    };

    if (services.length <= 0) fetchData();
  }, [services]);

  const handleFileChange = async (e: any) => {
    setFile(e.target.files[0]);
    setFileName(e.target.files[0].name);
  };

  const handleLocationChange = (e: any) => {
    setLocation(e.target.value);
  };

  const handleDescriptionChange = (e: any) => {
    setDescription(e.target.value);
  };

  const handleServiceChange = (e: any) => {
    setServiceId(e.target.value);
  };

  const handleCompression = () => {
    setToggleCompression((toggleCompression) => !toggleCompression);
  };

  const handleUpload = async () => {
    if (!invalidParameters()) {
      if (file === null || file === undefined) {
        AlertService.warning(ToastMessages.SELECT_FILE);
        return;
      }

      if (toggleCompression) {
        const compressedFile = await ImageCompressor.compress(file);
        setFile(compressedFile);
      }

      setUploading(true);

      if (serviceId)
        await FirebaseService.uploadFile(
          file,
          fileName,
          "Reviews"
        )
          .then(async (url: string) => {
            await GeocodeService.fromAddressToLatLng(location).then(
              async (response: any) => {
                const { lat, lng } = response.results[0].geometry.location;
                
                const payload: ReviewModel = {
                  description: description,
                  finishedAt: finishedAt,
                  imageUrl: url,
                  serviceId: serviceId,
                  location: location,
                  latitude: lat,
                  longitude: lng,
                };

                await handleCreate(payload).then(() => {
                  setUploading(false);
                  setDescription("");
                  setLocation("");
                  setFile(undefined);
                  handleClose();
                });
              }
            );
          })
          .catch(() => {
            setUploading(false);
          });
    }
  };

  const invalidParameters = () => {
    return (
      file === null ||
      file === undefined ||
      fileName === "" ||
      !serviceId ||
      description === "" ||
      location === ""
    );
  };

  return (
    <>
      {uploading && <Spinner />}
      <div>
        <Btn className={classes.headerButton} onClick={handleOpen}>
          + Add Item
        </Btn>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{ overflowY: "auto" }}
        >
          <Box sx={style}>
            <div className={classes.headerDiv}>
              <Typography className={classes.title}>Add Review</Typography>
              <Button onClick={handleClose} sx={{ color: "black" }}>
                <CloseIcon />
              </Button>
            </div>
            <hr />
            <div className={classes.inputGroup}>
              <form className={classes.inputDiv}>
                <input
                  type="text"
                  className={classes.input}
                  placeholder="Add a location"
                  onChange={handleLocationChange}
                />
                <div className={classes.inputDiv}></div>
                <select
                  className={classes.input}
                  onChange={handleServiceChange}
                  defaultValue={""}
                >
                  <option value="">
                    Select a service
                  </option>
                  {services.map((srvc: ServiceModel) => {
                    return (
                      <option key={srvc.id} value={srvc.id}>
                        {srvc.name}
                      </option>
                    );
                  })}
                </select>
                <textarea
                  className={classes.textareaReview}
                  placeholder="Add a description"
                  onChange={handleDescriptionChange}
                />
                <div
                  className={`calendar-container ${classes.calendarContainer}`}
                >
                  <Calendar
                    className={classes.calendar}
                    calendarType={"US"}
                    value={finishedAt}
                    onChange={(date: any) => setFinishedAt(date)}
                  />
                </div>
              </form>
              <div className={classes.inputDiv}>
                <div className={classes.uploadInputReview}>
                  <input
                    type="file"
                    id="add-single-img"
                    accept="image/*"
                    className="d-none"
                    onChange={handleFileChange}
                  />
                  <label
                    id="image-label"
                    htmlFor="add-single-img"
                    className={classes.label}
                  >
                    <img
                      src={UploadIcon}
                      className={classes.image}
                      alt="upload icon"
                    />
                    <label>Drop files to upload or browse</label>
                    <Typography>{fileName}</Typography>
                  </label>
                </div>
                <div className={classes.compressionDiv}>
                  <Typography>Compress the image: </Typography>
                  {toggleCompression ? (
                    <Button
                      sx={{ color: PrimaryColor }}
                      onClick={handleCompression}
                    >
                      <ToggleOnIcon />
                    </Button>
                  ) : (
                    <Button
                      sx={{ color: DarkGrey }}
                      onClick={handleCompression}
                    >
                      <ToggleOffIcon />
                    </Button>
                  )}
                </div>
              </div>
            </div>
            <div className={classes.buttonDiv}>
              <Btn
                className={classes.button}
                onClick={handleUpload}
                disabled={invalidParameters()}
              >
                Upload
              </Btn>
            </div>
            <ToastContainer />
          </Box>
        </Modal>
      </div>
    </>
  );
};

export default PostToReview;
