import React, { useState } from "react";
import { Box, Button, Modal } from "@mui/material";
import { Button as Btn, Typography } from "@material-ui/core";
import EditIcon from "../../assets/admin/edit-icon.svg";
import CloseIcon from "@mui/icons-material/Close";
import US_States from "../../model/enum/US_States";
import { NumberFormatter } from "react-number-formatter";
import UserModel from "../../model/user";
import RoleModel from "../../model/role";
import { Spinner } from "../../components";
import EmailService from "../../service/EmailService";
import useStyles from "./styles";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "35rem",
  bgcolor: "background.paper",
  border: "none",
  borderRadius: "8px",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
  "@media (max-width: 640px)": {
    width: "80%",
  },
};

const UserModal = ({ user, roles, handleCreate, handleUpdate }: any) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const edit = user != null;
  const id = edit ? user.id : 0;
  const [firstName, setFirstName] = useState(edit ? user.firstName : null);
  const [lastName, setLastName] = useState(edit ? user.lastName : null);
  const [email, setEmail] = useState(edit ? user.email : null);
  const [phone, setPhone] = useState(edit ? user.phone : null);
  const [zipCode, setZipCode] = useState(edit ? user.zip : null);
  const [street, setStreet] = useState(edit ? user.street : null);
  const [city, setCity] = useState(edit ? user.city : null);
  const [state, setState] = useState(edit ? user.state : null);
  const [role, setRole] = useState(edit ? user.Role : null);
  const [toggleSpinner, setToggleSpinner] = useState(false);

  const US_State = new US_States();

  const handleFirstName = (e: any) => {
    setFirstName(e.target.value);
  };

  const handleLastName = (e: any) => {
    setLastName(e.target.value);
  };

  const handleEmail = (e: any) => {
    e.preventDefault();
    setEmail(e.target.value);
  };

  const handlePhone = (e: any) => {
    setPhone(e);
  };

  const handleZipCode = (e: any) => {
    e.preventDefault();
    let zip = e.target.value;
    if (!isNaN(zip)) {
      if (US_State.isZipValid(zip)) {
        setCity(US_State.getStateCity(zip));
        setState(US_State.getStateName(zip));
      } else {
        setCity("");
        setState("");
      }
      setZipCode(zip);
    }
  };

  const handleStreet = (e: any) => {
    e.preventDefault();
    setStreet(e.target.value);
  };

  const handleCity = (e: any) => {
    e.preventDefault();
    setCity(e.target.value);
  };

  const handleState = (e: any) => {
    e.preventDefault();
    setState(e.target.value);
  };

  const handleRole = (e: any) => {
    e.preventDefault();
    let value = e.target.value;

    var rl = roles.find((x: RoleModel) => x.id == value);
    setRole(rl);
  };

  function generateUsername() {
    const randomNum = Math.floor(Math.random() * 10000); // Generate a random number up to 9999
    return `${firstName}${randomNum}`;
  }

  const register = async (): Promise<void> => {
    if (invalidInput()) return;

    if (!role) return;

    setToggleSpinner(true);

    const payload: UserModel = {
      id: id,
      username: edit ? user.username : generateUsername(),
      email: email,
      firstName: firstName,
      lastName: lastName,
      phone: phone,
      roleId: role.id,
      city: city,
      state: state,
      street: street,
      zip: zipCode,
    };

    if (edit) {
      await handleUpdate(payload);
    } else {
      payload.password = firstName + "123@";
      // await handleSignUp().then(async () => {
      await handleCreate(payload).then(async () => {
        await EmailService.sendEmailToUser(
          firstName,
          email,
          "Activate Account"
        );
      });
    }
    setFirstName("");
    setLastName("");
    setEmail("");
    setPhone("");
    setCity("");
    setState("");
    setStreet("");
    setZipCode("");
    setToggleSpinner(false);
    handleClose();
  };

  const invalidInput = () => {
    return (
      firstName === null ||
      email === null ||
      (email != null && email.indexOf("@") === -1) ||
      (email != null && email.indexOf(".") === -1) ||
      phone === null ||
      !role
    );
  };

  return (
    <div>
      {edit ? (
        <Button onClick={handleOpen}>
          <img src={EditIcon} alt="edit icon" />
        </Button>
      ) : (
        <Btn
          className={classes.headerButton}
          variant="contained"
          onClick={handleOpen}
        >
          + Add User
        </Btn>
      )}
      {open && (
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{ overflowY: "auto" }}
        >
          {toggleSpinner ? (
            <Spinner />
          ) : (
            <Box sx={style}>
              <div className={classes.headerDiv}>
                {edit ? (
                  <Typography className={classes.title}>
                    Edit user info
                  </Typography>
                ) : (
                  <Typography className={classes.title}>
                    Add new user
                  </Typography>
                )}
                <Button onClick={handleClose} sx={{ color: "black" }}>
                  <CloseIcon />
                </Button>
              </div>
              <hr />
              <form className={classes.form}>
                <div className={classes.inputGroup}>
                  <input
                    className={classes.input}
                    value={firstName}
                    onChange={handleFirstName}
                    placeholder="First name"
                  />
                  <input
                    className={classes.input}
                    value={lastName}
                    onChange={handleLastName}
                    placeholder="Last name"
                  />
                </div>
                <input
                  className={classes.input}
                  value={email}
                  type="email"
                  onChange={handleEmail}
                  placeholder="Email"
                />
                <div className={classes.input}>
                  <NumberFormatter
                    defaultCountry="USA"
                    placeholder="Phone"
                    value={phone}
                    getValue={(n: string) => handlePhone(n)}
                  />
                </div>
                <div className={classes.inputGroup}>
                  <input
                    className={classes.input}
                    value={street}
                    onChange={handleStreet}
                    placeholder="Street"
                  />
                  <input
                    className={classes.input}
                    value={zipCode}
                    onChange={handleZipCode}
                    placeholder="ZIP code"
                  />
                </div>
                <div className={classes.inputGroup}>
                  <input
                    className={classes.input}
                    value={city}
                    onChange={handleCity}
                    placeholder="City"
                  />
                  <input
                    className={classes.input}
                    value={state}
                    onChange={handleState}
                    placeholder="State"
                  />
                </div>
                <select
                  className={classes.input}
                  onChange={handleRole}
                  value={role?.id}
                >
                  <option value="">Select Role</option>
                  {roles.map((rl: RoleModel) => {
                    return (
                      <option key={rl.id} value={rl.id}>
                        {rl.name}
                      </option>
                    );
                  })}
                </select>
              </form>
              <div className={classes.buttonDiv}>
                <Btn
                  className={classes.button}
                  onClick={register}
                  disabled={invalidInput()}
                >
                  Save
                </Btn>
              </div>
            </Box>
          )}
        </Modal>
      )}
    </div>
  );
};

export default UserModal;
