export default class DatabaseEntities {
  static readonly ACCESS = "access";
  static readonly APPOINTMENTS = "appointments";
  static readonly AUTH = "auth";
  static readonly BUNDLES = "bundles";
  static readonly BUNDLE_ITEMS = "bundleItems";
  static readonly CATEGORIES = "categories";
  static readonly CLIENTS = "clients";
  static readonly CLIENT_LEVELS = "levels";
  static readonly CLIENT_TYPES = "types";
  static readonly EMAIL_TEMPLATES = "emailTemplates";
  static readonly EMPLOYES = "employees";
  static readonly EMPLOYEE_ROLES = "employees/roles";
  static readonly ERRORS = "errors";
  static readonly ESTIMATES = "estimates";
  static readonly FAQS = "faqs";
  static readonly GALLERY = "gallery";
  static readonly LOGS = "logs";
  static readonly MESSAGES = "messages";
  static readonly PAYMENT_METHODS = "paymentMethods";
  static readonly PERMISSIONS = "permissions";
  static readonly PRODUCTS = "products";
  static readonly PROJECT_BREAKDOWN = "";
  static readonly PROJECT_TRACKINGS = "";
  static readonly PROJECT_TYPES = "";
  static readonly PROJECTS = "projects";
  static readonly REQUESTS = "requests";
  static readonly REVIEWS = "reviews";
  static readonly ROLES = "roles";
  static readonly SERVICE_TYPES = "serviceTypes";
  static readonly SERVICES = "services";
  static readonly SESSIONS = "sessions";
  static readonly STAGED_PAYMENTS = "stagedPayments";
  static readonly STATUS = "status";
  static readonly TEAMS = "teams";
  static readonly TEAM_MEMBERS = "members";
  static readonly USERS = "users";
  static readonly VENDORS = "vendors";
}
