import React, { ChangeEvent, useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TransformDate from "../../utils/TransformDate";
import { PostToReview, Spinner } from "../index";
import MapIcon from "@mui/icons-material/Map";
import {
  PrimaryColor,
  TableBodyGrey,
  TableHeaderGrey,
} from "../../shared/styles/colors";
import ReviewModel from "../../model/review";
import DatabaseEntities from "../../model/enum/DatabaseEntities";
import LinkIcon from "@mui/icons-material/Link";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Card, CardContent, Typography, Button } from "@material-ui/core";
import { TablePagination } from "@mui/material";
import AlertService from "../../service/AlertService";
import ToastMessages from "../../model/enum/ToastMessages";
import { ToastContainer } from "react-toastify";
import useStyles from "../styles/cardStyles";
import ReviewDetailModal from "./ReviewDetailModal";
import CachedIcon from "@mui/icons-material/Cached";
import ApiService from "../../service/ApiService";

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: TableHeaderGrey,
    color: PrimaryColor,
    fontWeight: 700,
    whiteSpace: "nowrap",
    zIndex: 0,
    position: "static",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    color: TableBodyGrey,
    fontFamily: "monospace",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const Reviews = () => {
  const classes = useStyles();
  const [reviews, setReviews] = useState<ReviewModel[]>([]);
  const [items, setItems] = useState<ReviewModel[]>([]);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [toggleSpinner, setToggleSpinner] = useState(true);

  //Get all the reviews from the database
  useEffect(() => {
    const fetchData = async () => {
      await ApiService.get<ReviewModel[]>(`${DatabaseEntities.REVIEWS}`).then(
        (data: ReviewModel[]) => {
          if (data.length === 0) return;

          setReviews(data);
          setItems(data.slice(0, rowsPerPage));
        }
      );
      setToggleSpinner(false);
    };

    if (toggleSpinner && reviews.length <= 0) fetchData();
  }, [reviews]);

  const handleCreate = async (payload: any, notify: boolean = true) => {
    await ApiService.post<ReviewModel>(DatabaseEntities.REVIEWS, payload)
      .then(() => {
        if (notify) AlertService.succes(ToastMessages.REVIEW_CREATED);
        resetList();
      })
      .catch(() => {
        AlertService.error(ToastMessages.FAILED_UPLOAD);
      });
  };

  const handleUpdate = async (payload: any, notify: boolean = true) => {
    await ApiService.put<ReviewModel>(
      `${DatabaseEntities.REVIEWS}/${payload.id}`,
      payload
    )
      .then(() => {
        if (notify) AlertService.succes(ToastMessages.UPDATED);
        resetList();
      })
      .catch(() => {
        AlertService.error(ToastMessages.UPDATE_FAILED);
      });
  };

  const handleDelete = async (payload: any, notify: boolean = true) => {
    await ApiService.delete<any>(`${DatabaseEntities.REVIEWS}/${payload.id}`)
      .then(() => {
        if (notify) AlertService.succes(ToastMessages.DELETED);
        resetList();
      })
      .catch(() => {
        AlertService.error(ToastMessages.DELETE_FAILED);
      });
  };

  const resetList = (): void => {
    setReviews([]);
    setItems([]);
    setPage(0);
    setToggleSpinner(true);
  };

  const handlePageChange = (
    event: ChangeEvent<unknown> | null,
    pg: number,
    itemsPerPage: number = rowsPerPage
  ) => {
    setToggleSpinner(true);
    setPage(pg);
    let MaxElem = pg * itemsPerPage;

    items.forEach((_itm: ReviewModel, index) => {
      items.splice(index);
    });

    for (let i = MaxElem; i < MaxElem + itemsPerPage; i++) {
      if (i < reviews.length) items.push(reviews[i]);
    }

    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    setToggleSpinner(false);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    let pg = parseInt(event.target.value);
    setRowsPerPage((rowsPerPage) => pg);
    setPage((page) => 0);
    handlePageChange(null, 0, pg);
  };

  return (
    <>
      <Card raised className={classes.card}>
        <div className={classes.headerContainer}>
          <Typography className={classes.title}>Reviews</Typography>
          <div className={classes.headerDiv}>
            <PostToReview handleCreate={handleCreate} />
            <Button onClick={resetList}>
              <CachedIcon />
            </Button>
          </div>
        </div>
        <CardContent>
          <Paper>
            <TableContainer>
              <Table
                stickyHeader
                aria-label="customized table"
                padding="normal"
                size="medium"
              >
                <TableHead>
                  <TableRow>
                    <StyledTableCell align="right">Id</StyledTableCell>
                    <StyledTableCell align="right">Service</StyledTableCell>
                    <StyledTableCell align="right">Location</StyledTableCell>
                    <StyledTableCell align="right">Description</StyledTableCell>
                    <StyledTableCell align="right">Link</StyledTableCell>
                    <StyledTableCell align="right">Finished in</StyledTableCell>
                    <StyledTableCell align="right">Created At</StyledTableCell>
                    <StyledTableCell align="center">Map</StyledTableCell>
                    <StyledTableCell align="center">
                      <MoreVertIcon />
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                {toggleSpinner ? (
                  <Spinner />
                ) : (
                  <TableBody>
                    {items.length > 0 ? (
                      items.map((row: ReviewModel) => (
                        <StyledTableRow key={row.id}>
                          <StyledTableCell align="right">
                            {row.id}
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            {row.Service?.name}
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            {row.location}
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            {row.description}
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            <LinkIcon />
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            {TransformDate.toFullDate(row.finishedAt)}
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            {TransformDate.toFullDate(row.createdAt)}
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            <a
                              href={`http://maps.google.com/?q=${row.latitude}, ${row.longitude}`}
                              target="_blank"
                            >
                              <MapIcon />
                            </a>
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            <ReviewDetailModal
                              item={row}
                              handleUpdate={handleUpdate}
                              handleDelete={handleDelete}
                            />
                          </StyledTableCell>
                        </StyledTableRow>
                      ))
                    ) : (
                      <StyledTableCell align="right">No items</StyledTableCell>
                    )}
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Paper>
          <TablePagination
            component="div"
            count={reviews.length}
            page={page}
            onPageChange={handlePageChange}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </CardContent>
      </Card>
      <ToastContainer />
    </>
  );
};

export default Reviews;
