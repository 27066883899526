import React from "react";
import {
  Page,
  Text,
  Image,
  Document,
  StyleSheet,
  View,
} from "@react-pdf/renderer";
import Logo from "../../assets/main.png";
import { PrimaryColor, White } from "../../shared/styles/colors";
import EstimateModel from "../../model/estimate";
import TransformDate from "../../utils/TransformDate";
import BundleItem from "../../model/bundleItem";

const styles = StyleSheet.create({
  //style of the elements
  header: {
    fontSize: 14,
    fontWeight: "bold",
  },
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
    fontSize: 12,
  },
  infoView: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  infoText: {
    color: "rgba(0, 0, 0, 0.7)",
  },
  text: {
    textAlign: "justify",
  },
  image: {
    position: "absolute",
    width: "155px",
    height: "80px",
    right: 30,
    top: 45,
  },
  moreInfoView: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    marginTop: "2.5%",
  },
  estText: {
    marginTop: "20%",
    fontSize: 14,
    color: PrimaryColor,
  },
  colView: {
    display: "flex",
    flexDirection: "column",
    marginLeft: "30%",
  },
  rowView: {
    display: "flex",
    flexDirection: "row",
    marginLeft: "20%",
  },
  greyText: {
    color: "grey",
  },
  darkBlueTex: {
    color: PrimaryColor,
    fontWeight: "bold",
  },
  boldText: {
    fontWeight: "bold",
  },
  tableHeader: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    marginTop: "5%",
    color: White,
    backgroundColor: PrimaryColor,
    justifyContent: "space-between",
    padding: 5,
  },
  tableBody: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-between",
    padding: 10,
  },
  materialNoteView: {
    width: "75%",
    marginTop: "5%",
    fontSize: 10,
  },
  tableEnd: {
    width: "100%",
    marginTop: "5%",
    justifyContent: "space-evenly",
    color: "grey",
  },
  totalAmountView: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-between",
  },
  totalAmountText: {
    fontSize: 14,
    fontWeight: 900,
  },
  notesView: {
    width: "60%",
    marginTop: "10%",
    color: "grey",
    fontSize: 10,
  },
  pageNumber: {
    position: "absolute",
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
  },
});

interface Props {
  estimate: EstimateModel;
}

const EstimatePDFView = ({ estimate }: Props) => {
  return (
    <Document>
      {estimate && (
        <Page size="A4" style={styles.body}>
          <View style={styles.infoView}>
            <Text style={styles.header}>Setup Remodeling Inc</Text>
            <Text style={styles.infoText}>72 Cash St</Text>
            <Text style={styles.infoText}>Fall River, MA 02723 US</Text>
            <Text style={styles.infoText}>sales@setupremodeling.com</Text>
            <Text style={styles.infoText}>+1 (800)-530-4099</Text>
            <Text style={styles.infoText}>www.setupremodeling.com</Text>
          </View>
          <Text style={styles.estText}>Estimate</Text>
          <View style={styles.moreInfoView}>
            <View>
              <Text style={styles.greyText}>CUSTOMER</Text>
              <Text style={styles.text}>
                {estimate?.Client?.firstName} {estimate?.Client?.lastName}
              </Text>
              <Text style={styles.text}>{estimate?.Client?.street}</Text>
              <Text style={styles.text}>
                {estimate?.Client?.city}, {estimate?.Client?.state}{" "}
                {estimate?.Client?.zip}
              </Text>
            </View>
            <View style={styles.rowView}>
              <View style={styles.colView}>
                <Text style={styles.greyText}>ESTIMATE</Text>
                <Text style={styles.greyText}>DATE</Text>
              </View>
              <View style={styles.colView}>
                <Text style={styles.text}>#{estimate.code}</Text>
                <Text style={styles.text}>
                  {TransformDate.toFullDate(estimate.createdAt)}
                </Text>
              </View>
            </View>
          </View>
          <Image style={styles.image} src={Logo} />
          <View>
            {/** Table Header */}
            <View style={styles.tableHeader}>
              <Text>TASK</Text>
              <Text>DESCRIPTION</Text>
              <Text>QUANTITY</Text>
              <Text>PRICE</Text>
              <Text>TOTAL</Text>
            </View>
            {/** Table Body */}
            {estimate?.items?.map((item: any) => {
              return (
                <>
                  {/** Parent Rows */}
                  <View style={styles.tableBody} key={item.id}>
                    <View style={{ flex: 1, flexWrap: "wrap" }}>
                      <Text>{item.task}</Text>
                    </View>
                    <View style={{ flex: 2, flexWrap: "wrap" }}>
                      <Text>{item.description}</Text>
                    </View>
                    <View style={{ flex: 1, textAlign: "right" }}>
                      <Text>{item.quantity}</Text>
                    </View>
                    <View style={{ flex: 1, textAlign: "right" }}>
                      <Text>{item.rate}</Text>
                    </View>
                    <View style={{ flex: 1, textAlign: "right" }}>
                      <Text>${item.amount}.00</Text>
                    </View>
                  </View>
                  {/** Child Rows */}
                  <View style={{ marginLeft: 20 }}>
                    {item.BundleItems.map((child: BundleItem) => (
                      <View style={styles.tableBody} key={child.id}>
                        <View style={{ flex: 1, flexWrap: "wrap" }}>
                          <Text>{child.Product.name}</Text>
                        </View>
                        <View style={{ flex: 2, flexWrap: "wrap" }}>
                          <Text>{child.Product.description}</Text>
                        </View>
                        <View style={{ flex: 1, textAlign: "right" }}>
                          <Text>{child.quantity}</Text>
                        </View>
                        <View style={{ flex: 1, textAlign: "right" }}>
                          <Text>{child.Product.price}</Text>
                        </View>
                        <View style={{ flex: 1, textAlign: "right" }}>
                          <Text>
                            ${child.Product.price * child.quantity}.00
                          </Text>
                        </View>
                      </View>
                    ))}
                  </View>
                </>
              );
            })}
            {/** Note */}
            <View style={styles.materialNoteView}>
              <Text>
                Note: All jobs described above are included in this estimate,
                those that were not mentioned will be extra jobs to be billed
                separately.
              </Text>
            </View>
            <View style={styles.tableEnd}>
              <Text>
                - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
                - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
                - - - - - - - - -
              </Text>
            </View>
          </View>
          <View style={styles.totalAmountView}>
            <Text style={styles.greyText}>TOTAL</Text>
            <Text style={styles.darkBlueTex}>${estimate.total}.00</Text>
          </View>
          {estimate.discount > 0 && (
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                color: "lightgrey",
                fontSize: 12,
                marginTop: 10,
              }}
            >
              <Text>DISCOUNT</Text>
              <Text>{estimate.discount}%</Text>
            </View>
          )}
          {/** Additional notes */}
          <View style={styles.notesView}>
            <Text style={styles.infoText}>
              Notes: Payments can be made to Setup Remodeling Inc, by check
              according to the schedule specified below:
            </Text>
            <Text style={styles.infoText}>
              Down payment: 50% On the first day, we will start work. Second
              payment: 30% When more than 65% of the work is done. Last payment:
              20% When the job is done
            </Text>
          </View>
          <Text
            style={styles.pageNumber}
            render={({ pageNumber, totalPages }) =>
              `${pageNumber} / ${totalPages}`
            }
            fixed
          />
        </Page>
      )}
    </Document>
  );
};

export default EstimatePDFView;
