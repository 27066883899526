import { makeStyles } from "@material-ui/core/styles";
import {
  PrimaryColor,
  White,
  LightPrimaryColor,
  Black
} from "../../shared/styles/colors";

export default makeStyles(() => ({
  card: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    width: "50%",
    height: "25rem",
    marginLeft: "1rem",
    overflowY: "auto",
    "@media (max-width: 640px)": {
      width: "85%",
      marginTop: "5%",
      marginLeft: "0%",
    },
  },
  title_unavailable: {
    fontSize: "1.2rem",
    color: "red",
  },
  title_available: {
    fontSize: "1.2rem",
    color: "green",
  },
  list: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
  },
  //Checklist
  checklist: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "-2%",
    overflowX: "auto",
  },
  input: {
    marginTop: "3%",
    width: "100%",
  },
  notes: {
    display: "flex",
    flexDirection: "row",
    width: "40%",
    marginTop: "1rem",
  },
  noteCard: {
    width: "40%",
    marginTop: "1rem",
  },
  totalPriceDiv: {
    display: "flex",
    flexDirection: "row",
  },
  priceInput: {
    width: "5rem",
  },
  // Modal
  headerDiv: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "30px",
    padding: "15px",
    "@media (max-width: 640px)": {
      justifyContent: "space-around",
      alignItems: "center",
    },
  },
  headerSubDiv: {
    display: "flex",
    flexDirection: "row",
  },
  headerButton: {
    width: "100%",
    textTransform: "none",
    border: "none",
    color: White,
    background: PrimaryColor,
    "&:hover": {
      background: LightPrimaryColor,
    },
    "&:disabled": {
      color: "grey",
      cursor: "none",
    },
    "@media (max-width: 640px)": {
      width: "auto",
      marginTop: "10%",
    },
  },
  title: {
    color: Black,
    fontSize: "1.3rem",
    fontWeight: "700",
  },
  contentContainer: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    padding: "15px",
    background: White, //TODO: change
    "&:hover": {
      background: "rgba(209, 213, 222, 1)",
      "& > div:first-child ": {
        background: White,
      },
    },
  },
  roundedDiv: {
    display: "flex",
    width: "60px",
    height: "60px",
    borderRadius: "50px",
    alignItems: "center",
    justifyContent: "center",
    background: "rgba(209, 213, 222, 1)",
  },
  roundedUltraDiv: {
    display: "flex",
    width: "60px",
    height: "60px",
    borderRadius: "50px",
    alignItems: "center",
    justifyContent: "center",
    background: "rgba(209, 213, 222, 1)",
  },
  contentDiv: {
    display: "flex",
    flexDirection: "column",
    width: "80%",
    marginLeft: "20px",
  },
  subTitle: {
    color: Black,
    fontSize: "1.1rem",
    fontWeight: "700",
  },
  mainIcon: {
    width: "30px",
  },
  smallIcon: {
    width: "25px",
  },
}));
