import React, { useState, useEffect } from "react";
import Pathnames from "../model/enum/Pathnames";
import {
  Spinner,
  Contacts,
  Requests,
  Navbar,
  Appointments,
  Services,
  Overview,
  Clients,
  Gallery,
  Reviews,
  Login,
  Faqs,
  Errors,
  Logs,
  Users,
  UserProfile,
  OverviewTeam,
  Projects,
  ActivationAccount,
  Roles,
  Sidebar,
  Dashboard,
  Estimates
} from "./index";
import { Route, Routes } from "react-router-dom";
import { useCookies } from "react-cookie";
import { useLocation } from "react-router-dom";
import "./Admin.css";

const customStyles = {
  maxWidth: "2000px", // Desired width
};

const Admin = () => {
  //loggedIn user
  const [user, setUser] = useState();
  const [cookies, setCookie] = useCookies(["session"]);

  const [toggleSpinner, setToggleSpinner] = useState(false);
  const location = useLocation();

  let pathname = location.pathname;

  //Check if user is logged in
  useEffect(() => {
    document.title = "Admin - Setup Remodeling";

    if (
      cookies.session === null ||
      (cookies.session === undefined && allowedPaths())
    ) {
      window.open(Pathnames.LOGIN, "_self");
      setToggleSpinner(false);
    }
  }, [location.pathname]);

  const allowedPaths = (): boolean => {
    return (
      pathname !== Pathnames.LOGIN &&
      pathname !== Pathnames.ACTIVATE_ACCOUNT &&
      pathname.includes("admin")
    );
  };

  return (
    <>
      {toggleSpinner && <Spinner />}
      <div className="container" style={customStyles}>
        {allowedPaths() && <Sidebar />}
        <div className="main-area">
          <header className="navbar-content">
            {/* Navbar content */}
            {allowedPaths() && <Navbar />}
          </header>
          <main className="main-content">
            {/* Main content like tables */}
            <Routes>
              <Route path={Pathnames.LOGIN} element={<Login />} />
              <Route path={Pathnames.DASHBOARD} element={<Dashboard />} />
              <Route path={Pathnames.GALLERY_LIST} element={<Gallery />} />
              <Route path={Pathnames.REVIEWS_LIST} element={<Reviews />} />
              <Route
                path={Pathnames.APPOINTMENT_LIST}
                element={<Appointments />}
              />
              <Route path={Pathnames.CLIENTS_LIST} element={<Clients />} />
              <Route path={Pathnames.REQUESTS_LIST} element={<Requests />} />
              <Route path={Pathnames.CONTACTS_LIST} element={<Contacts />} />
              <Route path={Pathnames.SERVICES_LIST} element={<Services />} />
              <Route path={Pathnames.FAQ_LIST} element={<Faqs />} />
              <Route path={Pathnames.ERROR_LIST} element={<Errors />} />
              <Route path={Pathnames.LOGS_LIST} element={<Logs />} />
              <Route path={Pathnames.USER_LIST} element={<Users />} />
              <Route path={Pathnames.ESTIMATES_LIST} element={<Estimates />} />
              <Route path={Pathnames.PRODUCTS_SERVICES_LIST} element={<Overview />} />
              <Route path={Pathnames.PRODUCTS_SERVICES_BUNDLE_LIST} element={<Overview />} />
              <Route path={Pathnames.PRODUCTS_SERVICES_CATEGORY_LIST} element={<Overview />} />
              <Route path={Pathnames.PRODUCTS_SERVICES_VENDOR_LIST} element={<Overview />} />
              <Route path={Pathnames.PROFILE} element={<UserProfile />} />
              <Route path={Pathnames.TEAM_EMPLOYEE_LIST} element={<OverviewTeam />} />
              <Route path={Pathnames.TEAMS_LIST} element={<OverviewTeam />} />
              <Route path={Pathnames.EMPLOYEES_LIST} element={<OverviewTeam />} />
              <Route path={Pathnames.PROJECTS_LIST} element={<Projects />} />
              <Route
                path={Pathnames.ACTIVATE_ACCOUNT}
                element={<ActivationAccount />}
              />
              <Route path={Pathnames.ROLE_LIST} element={<Roles />} />
              {/* <Route path={Pathnames.EMAIL_LIST} element={< />} />
              <Route path={Pathnames.MESSAGE_LIST} element={< />} /> */}
            </Routes>
          </main>
        </div>
      </div>
    </>
  );
};

export default Admin;
