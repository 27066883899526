import React, { useEffect } from "react";
import { Button, Card, CardContent, Typography } from "@material-ui/core";
import { useLocation, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import {
  gtag_report_conversion,
  gtag_report_conversion_external,
} from "../../../utils/GoogleReport";
import FrameIcon from "../../../assets/Frame.svg";
import Pathnames from "../../../model/enum/Pathnames";
import useStyles from "./styles";

const ThanksPage = ({ service }: any) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();

  var visitGallery: boolean = service === "request";

  //gtag_report_conversion(location.pathname + location.search);
  //gtag_report_conversion_external(); disabled for main site and mkt

  // useEffect(() => {
  //   window.scrollTo(0, 0);
  //   setTimeout(() => {
  //     gtag_report_conversion(location.pathname + location.search);
  //     gtag_report_conversion_external();
  //   }, 5000);
  // }, [window.location.pathname]);

  return (
    <>
      <Helmet>
        <title>Thanks - Setup Remodeling</title>
        <meta name="description" content="Thank you for your request"></meta>
        <link rel="canonical" href="/thank-you" />
      </Helmet>
      <div className={classes.container}>
        <Card raised className={classes.card}>
          <CardContent className={classes.content}>
            <Typography className={classes.text}>
              Thank you for your {service}. We will get back to you promptly
            </Typography>
            <img src={FrameIcon} alt="thank you" className={classes.image} />
            {!visitGallery ? (
              <Button
                className={classes.button}
                onClick={() => navigate(Pathnames.GALLERY)}
              >
                Visit Gallery
              </Button>
            ) : (
              <Button
                className={classes.button}
                onClick={() => navigate(Pathnames.APPOINTMENT)}
              >
                Schedule Appointment
              </Button>
            )}
          </CardContent>
        </Card>
      </div>
    </>
  );
};

export default ThanksPage;
