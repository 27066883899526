import React, { useEffect, useState } from "react";
import { Box, Button, Modal } from "@mui/material";
import { Button as Btn, Typography } from "@material-ui/core";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CloseIcon from "@mui/icons-material/Close";
import TeamModel from "../../../model/team";
import TeamModal from "./TeamModal";
import TransformDate from "../../../utils/TransformDate";
import UserModel from "../../../model/user";
import useStyles from "./styles";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import WorkOutlineIcon from "@mui/icons-material/WorkOutline";
import DatabaseEntities from "../../../model/enum/DatabaseEntities";
import ApiService from "../../../service/ApiService";
import Employee from "../../../model/employee";
import EmployeeRole from "../../../model/employeeRole";
import Project from "../../../model/project";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50rem",
  bgcolor: "background.paper",
  border: "none",
  borderRadius: "8px",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
  maxHeight: "90vh", // Restrict maximum height to 80% of the viewport height
  overflowY: "auto", // Enable vertical scrolling
  "@media (max-width: 640px)": {
    width: "80%",
  },
};

interface Props {
  team: TeamModel;
  employees: Employee[];
  projects: Project[];
  roles: EmployeeRole[];
  handleUpdate: any;
  handleDelete: any;
}

type Member = {
  id: number;
  employeeId: number;
  employeeRoleId: number;
  Employee: Employee;
  EmployeeRole: EmployeeRole;
};

const TeamDetailModal = ({
  team,
  employees,
  projects,
  roles,
  handleUpdate,
  handleDelete,
}: Props) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [members, setMembers] = useState<Member[]>([]);
  const [toggleSpinner, setToggleSpinner] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      setToggleSpinner(true);
      await ApiService.get<Member[]>(
        `${DatabaseEntities.TEAMS}/${team.id}/${DatabaseEntities.TEAM_MEMBERS}`
      ).then((data: Member[]) => {
        if (data.length === 0) return;

        setMembers(data);
        setToggleSpinner(false);
      });
    };

    if (open && members.length === 0) {
      fetchData();
    }
  }, [open, members]);

  return (
    <div>
      <Button onClick={handleOpen}>
        <MoreVertIcon />
      </Button>
      {open && (
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{ overflowY: "auto" }}
        >
          <Box sx={style}>
            <div className={classes.headerDiv}>
              <div className={classes.headerSubDiv2}>
                <Typography className={classes.title}>Team</Typography>
                <div className="vl" style={{ marginLeft: "1rem" }}></div>
                <Typography
                  className={classes.subtitle}
                  style={{ marginLeft: "1rem" }}
                >
                  Info
                </Typography>
              </div>
              <div className={classes.headerSubDiv}>
                <TeamModal
                  team={team}
                  teamMembers={members}
                  employees={employees}
                  projects={projects}
                  roles={roles}
                  handleUpdate={handleUpdate}
                  handleDelete={handleDelete}
                />
                <Button onClick={handleClose} sx={{ color: "black" }}>
                  <CloseIcon />
                </Button>
              </div>
            </div>
            <hr />
            <form className={classes.form}>
              <Typography className={classes.bigTitle}>{team.name}</Typography>
              <div className={classes.detailDiv}>
                <div className={classes.detailSubDiv}>
                  <Typography className={classes.subtitle}>
                    {team.description}
                  </Typography>
                </div>
                <div className={classes.detailSubDiv}>
                  <div className={classes.detailSubDiv2}>
                    <Btn className={classes.button2} disabled>
                      <WorkOutlineIcon />
                      {team.User &&
                        `${team.User.firstName} ${team.User.lastName}`}
                    </Btn>
                    <div className={classes.rating}>
                      {[...Array(5)].map((_, index) => (
                        <span
                          key={index}
                          className={
                            index < Math.round(team?.Project?.rate)
                              ? classes.filledStar
                              : classes.emptyStar
                          }
                        >
                          ★
                        </span>
                      ))}
                    </div>
                  </div>
                  <div className={classes.inputDiv}>
                    <input
                      className={classes.inputDetail}
                      value={TransformDate.toMonthNameDateAndYear(
                        team.createdAt
                      )}
                      disabled
                    />
                    {/* <span className={classes.icon}>
                    <CalendarMonthIcon />
                  </span> */}
                  </div>
                  {team.Project && (
                    <input
                      className={classes.inputDetail}
                      value={team.Project.name}
                      disabled
                    />
                  )}
                </div>
              </div>
              <div>
                <div className={classes.headerSubDiv2}>
                  <Typography>Employees</Typography>
                </div>
                <div>
                  {members &&
                    members.map((employee: any) => (
                      <div className={classes.detailSubDiv2}>
                        <input
                          className={classes.inputDetail}
                          value={`${employee.Employee.firstName} ${employee.Employee.lastName}`}
                          disabled
                        />
                        <input
                          className={classes.inputDetail}
                          value={employee.EmployeeRole.name}
                          disabled
                        />
                      </div>
                    ))}
                </div>
              </div>
            </form>
          </Box>
        </Modal>
      )}
    </div>
  );
};

export default TeamDetailModal;
