import { makeStyles } from "@material-ui/core/styles";
import {
  PrimaryColor,
  White,
  LightPrimaryColor,
  Black,
  InputColor,
  LightGrey,
} from "../../../shared/styles/colors";

export default makeStyles(() => ({
  headerDiv: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    "@media (max-width: 640px)": {
      justifyContent: "space-around",
      alignItems: "center",
    },
  },
  headerSubDiv: {
    display: "flex",
    flexDirection: "row",
  },
  title: {
    color: Black,
    fontSize: "1.3rem",
    fontWeight: "700",
  },
  headerButton: {
    width: "100%",
    textTransform: "none",
    border: "none",
    color: White,
    background: PrimaryColor,
    "&:hover": {
      background: LightPrimaryColor,
    },
    "&:disabled": {
      color: "grey",
      cursor: "none",
    },
    "@media (max-width: 640px)": {
      width: "auto",
      marginTop: "10%",
    },
  },
  form: {
    marginTop: "5%",
    width: "100$",
    alignItems: "center",
    justifyContent: "center",
  },
  label: {
    fontWeight: "600",
  },
  inputContainer: {
    display: "flex",
    flexDirection: "column",
    width: "50% !important",
    "@media (max-width: 640px)": {
      width: "100% !important",
    },
  },
  inputGroup: {
    display: "flex",
    flexDirection: "row",
    width: "100% !important",
    alignItems: "center",
    "@media (max-width: 640px)": {
      marginTop: "10px",
      marginBottom: "10px",
    },
  },
  input: {
    width: "95% !important",
    borderRadius: "5px",
    padding: "0.5rem",
    margin: "0.5rem",
    border: ".5px solid #7E7D7D",
    background: White,
    color: InputColor,
    "@media (max-width: 640px)": {
      width: "100% !important",
    },
    "& div, input, button": {
      //child
      border: "none",
    },
  },
  textarea: {
    width: "95% !important",
    height: "5rem",
    borderRadius: "5px",
    padding: "0.5rem",
    margin: "0.5rem",
    border: ".5px solid #7E7D7D",
    background: White,
    color: InputColor,
    "@media (max-width: 640px)": {
      width: "100% !important",
      height: "6rem",
    },
  },
  image: {
    width: "30px",
    height: "30px",
    cursor: "pointer",
  },
  uploadInput: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "10rem",
    border: "1px #818181 dashed",
    borderRadius: "8px",
    alignItems: "center",
    justifyContent: "center",
    "@media (max-width: 640px)": {
      height: "8rem",
    },
  },
  checkbox: {
    accentColor: PrimaryColor,
    marginRight: "2%",
    marginLeft: "2%",
  },
  buttonDiv: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
  },
  button: {
    padding: "0.5rem",
    margin: "0.5rem",
    width: "12rem",
    color: White,
    background: PrimaryColor,
    borderRadius: "10px",
    textTransform: "none",
    "&:hover": {
      background: LightPrimaryColor,
    },
    "&:disabled": {
      color: "grey",
      cursor: "none",
    },
    "@media (max-width: 640px)": {
      width: "auto",
      marginTop: "10%",
    },
  },
  //Details
  inputDiv: {
    display: "flex",
    flexDirection: "column",
    width: "100% !important",
  },
  inputDetail: {
    width: "95% !important",
    border: "none",
    borderRadius: "5px",
    padding: "0.5rem",
    margin: "0.5rem",
    background: LightGrey,
    color: InputColor,
    "@media (max-width: 640px)": {
      width: "100% !important",
    },
    "& div, input, button": {
      //child
      border: "none",
    },
  },
  textareaDetail: {
    width: "95% !important",
    height: "10rem",
    border: "none",
    borderRadius: "5px",
    padding: "0.5rem",
    margin: "0.5rem",
    background: LightGrey,
    color: InputColor,
    "@media (max-width: 640px)": {
      width: "100% !important",
      height: "6rem",
    },
  },
  imageDetail: {
    maxWidth: "500px",
    maxHeight: "450px",
    marginLeft: "5%",
    "@media (max-width: 640px)": {
      marginLeft: "0%",
      width: "250px",
      height: "300px",
    },
  },
  producDiv: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    alignItems: "center",
    justifyContent: "space-between",
  },
  productName: {
    fontWeight: 700,
    fontSize: "1.2rem",
  },
  productDescription: {
    textAlign: "start",
    marginTop: "20px",
  },
  subTitle: {
    color: Black,
    fontSize: "1.1rem",
    fontWeight: "700",
  },
}));
