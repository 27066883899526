import React, { useState, useEffect, ChangeEvent } from "react";
import { Navbar, Spinner } from "../index";
import { Button } from "@material-ui/core";
import { Pagination } from "@mui/material";
import GalleryModel from "../../model/gallery";
import { Helmet } from "react-helmet-async";
import ApiService from "../../service/ApiService";
import useStyles from "./styles";
import GalleryResponse from "../../model/galleryResponse";
import Service from "../../model/service";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import FullscreenImage from "../../shared/components/images/FullscreenImage";

export default function Gallery() {
  const classes = useStyles();

  const [items, setItems] = useState<GalleryModel[]>([]);
  const [toggleSpinner, setToggleSpinner] = useState(true);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [services, setServices] = useState<Service[]>([]);
  const [service, setService] = useState<Service>();
  const [fullScreen, setFullScreen] = useState(false);
  const [imageUrl, setImageUrl] = useState<string>();

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    const url = new URL(window.location.href);
    if (service?.id) {
      url.searchParams.set("serviceId", service.id.toString());
    }
    url.searchParams.set("page", page.toString());
    window.history.pushState({}, "gallery", url);
  });

  useEffect(() => {
    const fetchData = async () => {
      setToggleSpinner(true);
      const queryParams = new URLSearchParams();
      if (service?.id) {
        queryParams.append("serviceId", service.id.toString());
      }
      queryParams.append("page", page.toString());

      await ApiService.get<GalleryResponse>(
        `frontend/gallery?${queryParams.toString()}`
      ).then((data) => {
        const { items, pageCount } = data;
        setItems(items);
        setTotalPages(pageCount);
        setTimeout(() => {
          setToggleSpinner(false);
        }, 2500);
      });
    };

    const fetchServices = async () => {
      await ApiService.get<Service[]>("frontend/galleryServices").then(
        (data) => {
          setServices(data);
        }
      );
    };

    if (services.length === 0) {
      fetchServices();
    }
    fetchData();
  }, [service, page]);

  const handlePageChange = (event: ChangeEvent<unknown>, pg: number) => {
    setPage(pg);
    scrollToTop();
  };

  const handleServiceClick = (srvc: Service | undefined) => {
    setService(srvc);
    setPage(1);
    setItems(() => []);
    scrollToTop();
  };

  const toggleFullScreen = (url: string) => {
    setImageUrl(url);
    setFullScreen((fullScreen) => !fullScreen);
    // Prevent window from scrolling
    document.body.style.overflow = url !== "" ? "hidden" : "auto";
  };

  return (
    <>
      <Helmet>
        <title>Gallery - Setup Remodeling</title>
        <meta
          name="description"
          content="Check our Gallery to view the excellence in our work. Setup Remodeling"
        ></meta>
        <link rel="canonical" href="/gallery" />
      </Helmet>
      {fullScreen && (
        <FullscreenImage
          imageUrl={imageUrl}
          toggleFullScreen={toggleFullScreen}
        />
      )}
      <div className={classes.container}>
        <div className={classes.titleDiv}>
          <span className={classes.title}>Our Work</span>
          <div className={classes.filters}>
            <Button
              className={
                service == undefined ? classes.buttonClicked : classes.button
              }
              disabled={service == undefined}
              onClick={() => handleServiceClick(undefined)}
            >
              All
            </Button>
            {services.map((srvc) => (
              <Button
                key={srvc.id}
                className={
                  srvc.id === service?.id
                    ? classes.buttonClicked
                    : classes.button
                }
                disabled={srvc.id === service?.id}
                onClick={() => handleServiceClick(srvc)}
              >
                / {srvc.name}
              </Button>
            ))}
          </div>
        </div>
        {toggleSpinner && <Spinner />}
        {items.length === 0 ? (
          <></>
        ) : (
          <>
            <div className="d-flex row justify-content-center">
              <ImageList
                variant={window.screen.width < 640 ? "quilted" : "masonry"}
                cols={3}
                gap={8}
                sx={{
                  width: 1300,
                  maxWidth: 1536,
                  "@media (max-width: 640px)": { width: "100%" },
                }}
              >
                {items.map((item) => (
                  <ImageListItem
                    key={item.id}
                    cols={3}
                    sx={{ cursor: "zoom-in" }}
                  >
                    <img
                      srcSet={`${item}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                      src={`${item.imageUrl}`}
                      alt={item.description}
                      loading="lazy"
                      onClick={() => toggleFullScreen(item.imageUrl)}
                    />
                    <ImageListItemBar
                      title={item.description}
                      subtitle={`Service: ${item.Service?.name}`}
                    />
                  </ImageListItem>
                ))}
              </ImageList>
              <div className={classes.pagination}>
                <Pagination
                  count={totalPages}
                  showFirstButton
                  showLastButton
                  onChange={handlePageChange}
                  page={page}
                />
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}
