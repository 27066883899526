import React, { useEffect, useState } from "react";
import { Box, Button, Modal } from "@mui/material";
import FirebaseService from "../../service/FirebaseService";
import DatabaseEntities from "../../model/enum/DatabaseEntities";
import DeleteModal from "../modal/DeleteModal";
import EditIcon from "../../assets/admin/edit-icon.svg";
import FullscreenImage from "../../shared/components/images/FullscreenImage";
import ServiceModel from "../../model/service";
import GalleryModel from "../../model/gallery";
import CloseIcon from "@mui/icons-material/Close";
import useStyles from "./styles";
import { Button as Btn, Typography } from "@material-ui/core";
import { Skeleton } from "@mui/material";
import Gallery from "../../model/gallery";
import ApiService from "../../service/ApiService";
import Service from "../../model/service";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50rem",
  bgcolor: "background.paper",
  border: "none",
  borderRadius: "8px",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
  maxHeight: "90vh", // Restrict maximum height to 80% of the viewport height
  overflowY: "auto", // Enable vertical scrolling
  "@media (max-width: 640px)": {
    width: "85%",
  },
};

interface Props {
  item: Gallery;
  handleUpdate: any;
  handleDelete: any;
}

const GalleryModal = ({ item, handleUpdate, handleDelete }: Props) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [fullScreen, setFullScreen] = useState(false);
  const [service, setService] = useState(item.Service);
  const [services, setServices] = useState<ServiceModel[]>([]);
  const [description, setDescription] = useState(item.description);
  const [link, setLink] = useState(item.imageUrl);

  const handleService = (e: any) => {
    e.preventDefault();
    setService(e.target.value);
  };

  const handleDescription = (e: any) => {
    e.preventDefault();
    setDescription(e.target.value);
  };

  const handleLink = (e: any) => {
    e.preventDefault();
    setLink(e.target.value);
  };

  const toggleFullScreen = () => {
    setFullScreen((fullScreen) => !fullScreen);
  };

  //Get all services from the database
  useEffect(() => {
    const fetchData = async () => {
      await ApiService.get<Service[]>(DatabaseEntities.SERVICES).then(
        (data) => {
          setServices(data);
        }
      );
    };

    if (services.length <= 0) fetchData();
  }, [services]);

  const submit = async () => {
    if (!service?.id) return;
    
    let payload: GalleryModel = {
      id: item.id,
      serviceId: service?.id,
      description: description,
      imageUrl: link,
    };

    await handleUpdate(payload);
    handleClose();
  };

  return (
    <div>
      <Button onClick={handleOpen}>
        <img src={EditIcon} alt="edit icon" />
      </Button>
      {fullScreen ? (
        <FullscreenImage imageUrl={link} toggleFullScreen={toggleFullScreen} />
      ) : (
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{ overflowY: "auto" }}
        >
          <Box sx={style}>
            <div className={classes.headerDiv}>
              <DeleteModal
                item={item}
                service={DatabaseEntities.REVIEWS}
                handleDelete={handleDelete}
              />
              <Button onClick={handleClose} sx={{ color: "black" }}>
                <CloseIcon />
              </Button>
            </div>
            <hr />
            <form className={classes.form}>
              <div className={classes.container}>
                <div className="d-flex row">
                  <div className="mt-2">
                    <Typography className={classes.label}>Service</Typography>
                    <select
                      onChange={handleService}
                      value={service?.id}
                      className={classes.input}
                    >
                      <option value="">Select a service</option>
                      {services.map((service: Service) => {
                        return (
                          <option key={service.id} value={service.id}>
                            {service.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="mt-2">
                    <Typography className={classes.label}>
                      Description
                    </Typography>
                    <input
                      className={classes.input}
                      value={description}
                      onChange={handleDescription}
                    />
                  </div>
                  <div className="mt-2">
                    <Typography className={classes.label}>Link</Typography>
                    <textarea
                      className={classes.textarea}
                      value={link}
                      onChange={handleLink}
                    />
                  </div>
                </div>
                {link ? (
                  <img
                    className={classes.image}
                    src={link}
                    alt="gallery img"
                    onClick={() => toggleFullScreen()}
                  />
                ) : (
                  <Skeleton variant="rectangular" width={650} height={460} />
                )}
              </div>
            </form>
            <div className={classes.buttonDiv}>
              <Btn className={classes.button} onClick={submit}>
                Save
              </Btn>
            </div>
          </Box>
        </Modal>
      )}
    </div>
  );
};

export default GalleryModal;
