import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableContainer,
  TableCell,
  Paper,
} from "@mui/material";
import { tableCellClasses } from "@mui/material/TableCell";
import TransformDate from "../../../utils/TransformDate";
import {
  PrimaryColor,
  TableBodyGrey,
  TableHeaderGrey,
} from "../../../shared/styles/colors";
import DatabaseEntities from "../../../model/enum/DatabaseEntities";
import { Typography, Button } from "@material-ui/core";
import { TablePagination } from "@mui/material";
import { Spinner } from "../../../components";
import { ToastContainer } from "react-toastify";
import ApiService from "../../../service/ApiService";
import AlertService from "../../../service/AlertService";
import ToastMessages from "../../../model/enum/ToastMessages";
import CachedIcon from "@mui/icons-material/Cached";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CategoryModel from "../../../model/category";
import CategoryDetailModal from "./CategoryDetailModal";
import useStyles from "../../styles/cardStyles";

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: TableHeaderGrey,
    color: PrimaryColor,
    fontWeight: 700,
    whiteSpace: "nowrap",
    zIndex: 0,
    position: "static",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    color: TableBodyGrey,
    fontFamily: "monospace",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const Categories = () => {
  const classes = useStyles();
  const [categories, setCategories] = useState<CategoryModel[]>([]);
  const [items, setItems] = useState<CategoryModel[]>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [toggleSpinner, setToggleSpinner] = useState(true);

  // Get all the categories items from the database
  useEffect(() => {
    const fetchData = async () => {
      await ApiService.get<CategoryModel[]>(DatabaseEntities.CATEGORIES).then(
        (data) => {
          if (data.length == 0) setToggleSpinner(false);

          setCategories(data);
          setItems(data.slice(0, rowsPerPage));
          setToggleSpinner(false);
        }
      );
    };

    if (toggleSpinner && categories.length === 0) fetchData();
  }, [categories]);

  const handleUpdate = async (payload: any, notify: boolean = true) => {
    await ApiService.put<CategoryModel>(
      `${DatabaseEntities.CATEGORIES}/${payload.id}`,
      payload
    )
      .then(() => {
        setToggleSpinner(true);
        if (notify) AlertService.succes(ToastMessages.UPDATED);
        resetList();
      })
      .catch(() => {
        AlertService.error(ToastMessages.UPDATE_FAILED);
      });
  };

  const handleDelete = async (payload: any, notify: boolean = true) => {
    await ApiService.delete<any>(`${DatabaseEntities.CATEGORIES}/${payload.id}`)
      .then(() => {
        setToggleSpinner(true);
        if (notify) AlertService.succes(ToastMessages.DELETED);
        resetList();
      })
      .catch(() => {
        AlertService.error(ToastMessages.DELETE_FAILED);
      });
  };

  const resetList = () => {
    setCategories([]);
    setItems([]);
    setPage(0);
    setToggleSpinner(true);
  };

  const handlePageChange = (
    event: React.ChangeEvent<unknown> | null,
    pg: number,
    itemsPerPage: number = rowsPerPage
  ) => {
    setToggleSpinner(true);
    setPage(pg);
    let MaxElem = pg * itemsPerPage;

    items.forEach((_itm: CategoryModel, index) => {
      items.splice(index);
    });

    for (let i = MaxElem; i < MaxElem + itemsPerPage; i++) {
      if (i < categories.length) items.push(categories[i]);
    }

    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    setToggleSpinner(false);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    let pg = parseInt(event.target.value);
    setRowsPerPage((rowsPerPage) => pg);
    setPage((page) => 0);
    handlePageChange(null, 0, pg);
  };

  return (
    <>
      <div className={classes.headerContainer}>
        <Typography className={classes.title}></Typography>
        <div className={classes.headerDiv}>
          <Button onClick={resetList}>
            <CachedIcon />
          </Button>
        </div>
      </div>
      <Paper sx={{ marginTop: "20px" }}>
        <TableContainer>
          <Table
            stickyHeader
            aria-label="customized table"
            padding="normal"
            size="medium"
          >
            <TableHead>
              <TableRow>
                <StyledTableCell align="right">Id</StyledTableCell>
                <StyledTableCell align="right">Name</StyledTableCell>
                <StyledTableCell align="right">Created At</StyledTableCell>
                <StyledTableCell align="right">
                  <MoreVertIcon />
                </StyledTableCell>
              </TableRow>
            </TableHead>
            {toggleSpinner ? (
              <Spinner />
            ) : (
              <TableBody>
                {items.length > 0 ? (
                  items.map((row: CategoryModel) => (
                    <StyledTableRow key={row.id}>
                      <StyledTableCell align="right">{row.id}</StyledTableCell>
                      <StyledTableCell align="right">
                        {row.name}
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        {TransformDate.toFullDate(row.createdAt)}
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        <CategoryDetailModal
                          category={row}
                          handleUpdate={handleUpdate}
                          handleDelete={handleDelete}
                        />
                      </StyledTableCell>
                    </StyledTableRow>
                  ))
                ) : (
                  <StyledTableCell align="right">No items</StyledTableCell>
                )}
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </Paper>
      <TablePagination
        component="div"
        count={categories.length}
        page={page}
        onPageChange={handlePageChange}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <ToastContainer />
    </>
  );
};

export default Categories;
