import { makeStyles } from "@material-ui/core/styles";
import { DarkBlueText, PrimaryColor, LightPrimaryColor, BackgroundBlue } from "../../shared/styles/colors";

export default makeStyles(() => ({
    container: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        width: '100%',
        background: BackgroundBlue
    },
    titleDiv: {
        marginTop: '150px',
        "@media (max-width: 640px)": {
            marginTop: '100px',
        },
    },
    title: {
        textAlign: 'center',
        fontSize: '1.5rem',
        fontWeight: 'bold',
        color: DarkBlueText
    },
    filters: {
        display: "list-item",
        width: "100%",
        marginTop: '1.5rem !important',
        marginBottom: '1.5rem !important',
        overflowX: "auto",
    },
    button: {
        color: LightPrimaryColor,
    },
    buttonClicked: {
        color: PrimaryColor,
        fontWeight: 'bold',
        textDecoration: 'underline',
        textUnderlineOffset: '5px',
        '&:disabled': {
            color: PrimaryColor
        }
    },
    pagination: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '2.5%',
        marginBottom: '2.5%',
    }
}));