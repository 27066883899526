import * as React from "react";
import { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {
  PrimaryColor,
  TableHeaderGrey,
  TableBodyGrey,
} from "../../shared/styles/colors";
import DatabaseEntities from "../../model/enum/DatabaseEntities";
import AlertService from "../../service/AlertService";
import ApiService from "../../service/ApiService";
import ToastMessages from "../../model/enum/ToastMessages";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Card, CardContent, Typography, Button } from "@material-ui/core";
import { TablePagination } from "@mui/material";
import { ToastContainer } from "react-toastify";
import { Spinner } from "../../components";
import CachedIcon from "@mui/icons-material/Cached";
import RoleModel from "../../model/role";
import TransformDate from "../../utils/TransformDate";
import RoleModal from "./RoleModal";
import RoleDetailModal from "./RoleDetailModal";
import useStyles from "../styles/cardStyles";

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: TableHeaderGrey,
    color: PrimaryColor,
    fontWeight: 700,
    whiteSpace: "nowrap",
    zIndex: 0,
    position: "static",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    color: TableBodyGrey,
    fontFamily: "monospace",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const Roles = () => {
  const classes = useStyles();
  const [roles, setRoles] = useState<RoleModel[]>([]);
  const [items, setItems] = useState<RoleModel[]>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [toggleSpinner, setToggleSpinner] = useState(true);

  //Get all appointments from the database
  useEffect(() => {
    const fetchData = async () => {
      await ApiService.get<RoleModel[]>(DatabaseEntities.ROLES).then(
        (data: RoleModel[]) => {
          if (data.length === 0) return;

          setRoles(data);
          setItems(data.slice(0, rowsPerPage));
        }
      );
      setToggleSpinner(false);
    };

    if (toggleSpinner && roles.length === 0) fetchData();
  }, [roles]);

  const handleCreate = async (payload: any, notify: boolean = true) => {
    await ApiService.post<RoleModel>(DatabaseEntities.ROLES, payload)
      .then(() => {
        if (notify) AlertService.succes(ToastMessages.CREATED);
        resetList();
      })
      .catch(() => {
        AlertService.error(ToastMessages.CREATED_FAILED);
      });
  };

  const handleUpdate = async (payload: any, notify: boolean = true) => {
    await ApiService.put<RoleModel>(
      `${DatabaseEntities.ROLES}/${payload.id}`,
      payload
    )
      .then(() => {
        if (notify) AlertService.succes(ToastMessages.UPDATED);
        resetList();
      })
      .catch(() => {
        AlertService.error(ToastMessages.UPDATE_FAILED);
      });
  };

  const handleDelete = async (payload: any, notify: boolean = true) => {
    await ApiService.delete<any>(`${DatabaseEntities.ROLES}/${payload.id}`)
      .then(() => {
        if (notify) AlertService.succes(ToastMessages.DELETED);
        resetList();
      })
      .catch(() => {
        AlertService.error(ToastMessages.DELETE_FAILED);
      });
  };

  const resetList = (): void => {
    setRoles([]);
    setItems([]);
    setPage(0);
    setToggleSpinner(true);
  };

  const handlePageChange = (
    event: React.ChangeEvent<unknown> | null,
    pg: number,
    itemsPerPage: number = rowsPerPage
  ) => {
    setToggleSpinner(true);
    setPage(pg);
    let MaxElem = pg * itemsPerPage;

    items.forEach((_itm: RoleModel, index) => {
      items.splice(index);
    });

    for (let i = MaxElem; i < MaxElem + itemsPerPage; i++) {
      if (i < roles.length) items.push(roles[i]);
    }

    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    setToggleSpinner(false);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    let pg = parseInt(event.target.value);
    setRowsPerPage((rowsPerPage) => pg);
    setPage((page) => 0);
    handlePageChange(null, 0, pg);
  };

  return (
    <>
      <Card raised className={classes.card}>
        <div className={classes.headerContainer}>
          <Typography className={classes.title}>Roles</Typography>
          <div className={classes.headerDiv}>
            <RoleModal handleCreate={handleCreate} />
            <Button onClick={resetList}>
              <CachedIcon />
            </Button>
          </div>
        </div>
        <CardContent>
          <Paper>
            <TableContainer>
              <Table
                stickyHeader
                aria-label="customized table"
                padding="normal"
                size="medium"
              >
                <TableHead>
                  <TableRow>
                    <StyledTableCell align="right">Id</StyledTableCell>
                    <StyledTableCell align="right">Name</StyledTableCell>
                    <StyledTableCell align="right">Permissions</StyledTableCell>
                    <StyledTableCell align="right">CreatedAt</StyledTableCell>
                    <StyledTableCell align="center">
                      <MoreVertIcon />
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                {toggleSpinner ? (
                  <Spinner />
                ) : (
                  <TableBody>
                    {items.length > 0 ? (
                      items.map((row: RoleModel) => (
                        <StyledTableRow key={row.id}>
                          <StyledTableCell align="right">
                            {row.id}
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            {row.name}
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            {/* {row.Permissions?.map((perm) => (
                              <Typography style={{ fontFamily: "monospace" }}>
                                {perm}
                              </Typography>
                            ))} */}
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            {TransformDate.toFullDate(row.createdAt)}
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            <RoleDetailModal
                              role={row}
                              handleUpdate={handleUpdate}
                              handleDelete={handleDelete}
                            />
                          </StyledTableCell>
                        </StyledTableRow>
                      ))
                    ) : (
                      <StyledTableCell align="right">No items</StyledTableCell>
                    )}
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Paper>
          <TablePagination
            component="div"
            count={roles.length}
            page={page}
            onPageChange={handlePageChange}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </CardContent>
      </Card>
      <ToastContainer />
    </>
  );
};

export default Roles;
